import React from "react";
import Image from "~/components/elements/Image";
import { FadedLineSimpleIconGrid } from "~/components/elements/Icon";

export const SimpleIconGrid = ({ items }) => (
	<div className="relative lg:pt-12">
		<div className="absolute hidden lg:block top-[-30%] right-[85%]">
			<FadedLineSimpleIconGrid />
		</div>
		<div className="lg:ml-[25%] grid md:grid-cols-2 gap-4 lg:gap-12">
			{items.map(({image, heading}, i) => (
				<div key={i} className="flex space-x-6 items-center">
					<div className="w-[53px] opacity-10">
						<Image image={image} />
					</div>
					<h4 className="flex-1 t-22">{heading}</h4>
				</div>
			))}
		</div>
	</div>
)