import React from 'react';
import Image from '~/components/elements/Image';

export const BenefitsGrid = ({ items }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {items.map((item, i) => (
        <BenefitsGridItem {...item} key={i} />
      ))}
    </div>
  );
};

export const BenefitsGridItem = ({ background, style, heading, description, image }) => {
  const innerClasses = ['relative'];
  const imageClasses = ['absolute'];
  const containerClasses = ['relative p-6 pt-8 md:pt-12 md:p-12 rounded-medium overflow-hidden'];

  if (background === 'dark') {
    containerClasses.push('bg-dark text-white');
  } else if (background === 'blue') {
    containerClasses.push('bg-blue text-white text-white-p-fix');
  } else {
    containerClasses.push('bg-mgrey');
  }

  if (style === 'default') {
    innerClasses.push('w-[50%]');
    imageClasses.push('top-0 right-0 bottom-0 w-[90%]');
  } else if (style === 'wide') {
    containerClasses.push('md:col-span-2 py-24');
    innerClasses.push('md:pl-[15%] sm:pl-[25%]  md:pl-[60%]');
    imageClasses.push('top-0 left-0 bottom-0 w-[100%]');
  } else if (style === 'tall') {
    containerClasses.push('md:row-span-2 flex flex-col');
    innerClasses.push('');
    imageClasses.push('!static mt-auto order-1 -ml-12 -mb-12 -mr-12 left-0 right-0 bottom-0 h-[70%]');
  }

  return (
    <div className={containerClasses.join(' ')}>
      <div className={imageClasses.join(' ')}>
        <Image objectFit="cover" imageClasses="object-cover w-full h-full" image={image} />
      </div>
      <div className={innerClasses.join(' ')}>
        <h6 className="t-22 mb-4">{heading}</h6>
        <p className="t-18 leading-[1.6]">{description}</p>
      </div>
    </div>
  );
};
