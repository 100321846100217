import React from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { ArrowLink } from '~/components/elements/Typography';
import { ArrowRight } from '~/components/elements/Icon';

export const CareerLanding = ({ ctaHeading, ctaImage, ctaLink, careers, description, heading }) => (
	<section id="vacancies" className="bg-mgrey section section-top section-bot container-bound">
		<div className="container max-w-[1080px]">
			<div className="text-center">
				<h2 className="t-40 mb-2">{heading}</h2>
				<p className="t-18">{description}</p>
			</div>
			<div className="my-10 space-y-3">
				{careers?.map((career, i) => (
					<a
						target="_blank"
						href={`${career?.career?.email}`}
						className="flex items-center bg-white career-shadow rounded-[7px] py-5 md:py-7 px-6 md:px-10"
					>
						<div className="space-y-2.5 md:space-y-0">
							<div className="t-22">{career?.career?.title}</div>
							<div className="flex flex-col md:flex-row t-18 text-navy text-opacity-60 md:space-x-2.5">
								{career?.career?.department && (
									<div className="flex items-center">
										{career?.career?.department}{' '}
										<div className="hidden md:block w-1 h-1 bg-navy bg-opacity-60 rounded-[100%] ml-2.5" />
									</div>
								)}
								{career?.career?.hours && (
									<div className="flex items-center">
										{career?.career?.hours}{' '}
										<div className="hidden md:block w-1 h-1 bg-navy bg-opacity-60 rounded-[100%] ml-2.5" />
									</div>
								)}
								{career?.career?.location && <div className="flex items-center">{career?.career?.location} </div>}
								{/* {career.career.location && <div>{career.career.location}</div>} */}
							</div>
						</div>
						<button
							className={`ml-auto w-[37px] h-[37px] rounded-[100%] bg-blue transition-colors duration-300 ease-in-out flex items-center justify-center`}
						>
							<div className="w-[10px] fw-icon icon-white">
								<ArrowRight />
							</div>
						</button>
					</a>
				))}
			</div>
			<div className="bg-navy text-white p-6 md:p-[55px] relative rounded-medium overflow-hidden">
				<div className="hidden md:block absolute bottom-0 right-16 w-[35%]">
					<Image objectFit="contain" image={ctaImage} />
				</div>
				<div className="relative md:w-[50%]">
					<h3 className="t-22 mb-1 md:mb-3">{ctaHeading}</h3>
					<div className="text-sky font-medium">
						<ArrowLink link={ctaLink} />
					</div>
				</div>
			</div>
		</div>
	</section>
);
