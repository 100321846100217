import React from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import parse from 'html-react-parser';

export default function Footer({ data }) {
  const footer = data.globalFooter.footer;
  const { contact, copyright, legalMenu, menus, social, certification } = footer;

  console.log(certification);

  return (
    <footer className="pt-12 pb-8 bg-white container-bound lg:pt-20" role="contentinfo">
      <div className="container">
        <div className="flex flex-wrap">
          {menus.map((menu, i) => (
            <div key={i} className="w-1/2 pb-10 pr-4 lg:pb-0 lg:flex-1">
              <FooterTitle title={menu.heading} />
              <nav className="space-y-3">
                {menu.links.map((item, i) => (
                  <Link
                    className="block transition-colors duration-200 ease-in-out hover:text-sky"
                    key={i}
                    to={item.link.url}
                  >
                    {item.link.title}
                  </Link>
                ))}
              </nav>
            </div>
          ))}
          <div className="flex-1">
            <FooterTitle title="Contact us" />
            <div className="t-18">{parse(contact)}</div>
            <SocialLinks social={social} />
          </div>
        </div>
        <div className="mt-[62px] flex space-x-[14px]">
          {certification?.map((cert, i) => (
            <Image key={`cert${i}`} image={cert?.image} className="max-w-[67px]" />
          ))}
        </div>
      </div>

      <div className="pt-8 mt-8 border-t md:mt-9 border-t-mgrey">
        <div className="container items-center text-center md:text-left md:flex">
          <div className="text-[12px] opacity-60">{copyright}</div>
          <div className="mt-4 ml-auto space-y-1 md:mt-0 md:space-y-0 md:space-x-8">
            {legalMenu.map((item, i) => (
              <Link
                className="text-[12px] font-medium block md:inline-block transition-opacity duration-200 ease-in-out hover:opacity-60"
                key={i}
                to={item.links.url}
              >
                {item.links.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

export const FooterTitle = ({ title }) => <h6 className="text-[12px] font-medium uppercase mb-4">{title}</h6>;

export const SocialLinks = ({ social }) => {
  return (
    <div className="pt-4 space-x-5 invert-on-dark">
      {social.map((item, i) => (
        <Link
          className="w-[19px] inline-block transition-opacity duration-200 ease-in-out hover:opacity-60"
          key={i}
          to={item.link.url}
        >
          <Image image={item.icon} />
        </Link>
      ))}
    </div>
  );
};
