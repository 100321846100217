import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Stats = ({ stats }) => {
	const controls = useAnimation();
	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
		if (!inView) {
			controls.start('hidden');
		}
	}, [controls, inView]);

	return (
		<div ref={ref} className="grid grid-cols-2 lg:grid-cols-4 gap-2 text-center">
			{stats.map((stat, i) => {
				const isLong = stat.stat.length > 4;
				return (
					<motion.div
						initial="hidden"
						variants={{
							hidden: { opacity: 0, y: 15 },
							visible: {
								opacity: 1,
								y: 0,
								transition: {
									duration: 0.5,
									delay: 0.3 + i * 0.3,
								},
							},
						}}
						animate={controls}
						key={`card-grid-${i}`}
						className="bg-white py-8 px-4 xl:py-12 xl:px-10 rounded-medium"
						key={i}
					>
						<h6 className={`${isLong ? 't-44 !font-bold' : 't-54'} leading-[1em] font-bold tracking-[-0.05em]`}>
							{stat.stat}
						</h6>
						<p className="t-16 is-tight mt-3">{stat.description}</p>
					</motion.div>
				);
			})}
		</div>
	);
};
