import React from "react";
import { ContentRenderer } from "~/components/layouts/LayoutRenderer";

export const TwoColumns = ({ num, prefix, a, b, flipColumns, flipColumnsMobile, style, columnSetup, imageWidthOptions, columnAInnerWidthOptions, containerWidth, disableCentering, insetLeftColumn }) => {
	
	const gridClasses = [];

	const colAClasses = ["col-a relative z-[2]"];
	const colBClasses = ["col-b"];
	const colAInnerClasses = [];

	// Column sizes
	if(columnSetup === "1-2"){
		colAClasses.push("md:w-[39%]");
		colBClasses.push("flex-1");
	} else {
		colAClasses.push("flex-1");
		if(style === "boxed"){
			gridClasses.push("boxed-columns");
			colBClasses.push("md:w-[50%] xl:w-[52%]");
		} else {
			if(!disableCentering){
				gridClasses.push("items-center");
			}
			// Variable image width sizes
			colBClasses.push("md:w-[45%] lg:w-[50%]");
			if(imageWidthOptions === "60"){
				colBClasses.push("xl:w-[60%]");
			} else if(imageWidthOptions === "56"){
				colBClasses.push("xl:w-[56%]");
			} else if(imageWidthOptions === "54"){
				colBClasses.push("xl:w-[54%]");
			} else if(imageWidthOptions === "52"){
				colBClasses.push("xl:w-[51.25%]");
				colAClasses.push("xl:w-[45%]");
			} else if(imageWidthOptions === "49"){
				colBClasses.push("xl:w-[49%]");
			}  else if(imageWidthOptions === "44"){
				colBClasses.push("xl:w-[44%]");
			} else {
				colBClasses.push("xl:w-[59%]");
			}
		}
	}

	// Flip
	if(flipColumns){
		gridClasses.push("md:flex-row-reverse");
	}

	
	if(flipColumnsMobile){
		gridClasses.push("flip-columns-mobile ");
	}


	// Box and padding 
	if(style === 'boxed'){
		gridClasses.push("bg-white rounded-large overflow-hidden");
		colAClasses.push("pt-4 pb-8 px-8 md:py-14 md:px-16 xl:py-32 xl:px-24");
		colBClasses.push("min-h-[200px] md:min-h-0");
	} else {
		if(flipColumns){
			colBClasses.push("md:pr-10 xl:pr-24");
		} else {
			colAClasses.push("md:pr-10 xl:pr-24");
		}
	}

	// Inset Left column on Xlarge
	if(insetLeftColumn){
		colAClasses.push("xl:pl-20");
	}

	// Inner max width
	if(columnAInnerWidthOptions){
		if(columnAInnerWidthOptions === "410"){
			colAInnerClasses.push("max-w-[410px]");
		} else if(columnAInnerWidthOptions === "425"){
			colAInnerClasses.push("max-w-[440px]");
		} else if(columnAInnerWidthOptions === "450"){
			colAInnerClasses.push("max-w-[450px]");
		}
	}

	const containerClasses = ["container"];
	if(containerWidth){
		if(containerWidth === "950"){
			containerClasses.push("max-w-[950px]");
		} else if(containerWidth === "1050"){
			containerClasses.push("max-w-[1050px]");
		}
	}

	// Check if we should flip a and b on mobile if media is in col b
	if(b.content && b.content[0] && b.content[0].mediaType){
		gridClasses.push("flex md:flex-row flex-col-reverse");
		colBClasses.push("w-full mb-8 md:mb-0")
	} else {
		gridClasses.push("space-y-4 md:space-y-0 md:flex");
	}

	return (
		<div className={containerClasses.join(" ")}>
			<div className={gridClasses.join(" ")}>
				<div className={colAClasses.join(" ")}>
					{colAInnerClasses.length > 0 ? (
						<div className={colAInnerClasses.join(" ")}>
							<ContentRenderer num={num} content={a.content} prefix={prefix+"TwoColumns_A_Content_"} />
						</div>
					) : (
						<ContentRenderer num={num} content={a.content} prefix={prefix+"TwoColumns_A_Content_"} />
					)}
				</div>
				<div className={colBClasses.join(" ")}>
					<ContentRenderer num={num} content={b.content} prefix={prefix+"TwoColumns_B_Content_"} />
				</div>
			</div>
		</div>
	);
}
