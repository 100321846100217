import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { Masthead } from '~/components/article/Masthead';
import { ArticleFlexible } from '~/components/article/ArticleFlexible';
import { ArticleContent } from '~/components/article/ArticleContent';
import { Related } from '~/components/article/Related';
import { Share } from '~/components/article/Share';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function ArticlePage({ data: { wpPost, wpPartner, wp } }) {
  const post = wpPost ? wpPost : wpPartner;
  const footerBlock = wp.footer?.globalFooter?.footer?.resourcesCta?.layouts;
  return (
    <Layout wp={wp} post={post}>
      <Seo post={post} />
      <Masthead post={post} />
      <div className="bg-white container-bound py-10 md:py-24">
        {post.nodeType === 'Partner' ? (
          <div className="container">
            <ArticleFlexible post={post} />
          </div>
        ) : (
          <ArticleFlexibleWithShare post={post} />
        )}
      </div>
      <Related post={post} />
      {footerBlock && <LayoutRenderer prefix="ReusableBlock_Layouts_Sections_Section_Layout_" layouts={footerBlock} />}
    </Layout>
  );
}

export const ArticleFlexibleWithShare = ({ post }) => (
  <div className="container relative flex">
    <div className="sticky w-[56px] top-0 left-0 hidden lg:block mb-auto">
      <Share isSidebar={true} />
    </div>
    <div className="flex-1 xl:mr-[56px]">
      <ArticleContent post={post} />
      <ArticleFlexible post={post} />
      <div className="text-center pt-8">
        <Share />
      </div>
    </div>
  </div>
);

export const formatArticleDate = (dateString) => new Date(dateString).toDateString().split(' ').slice(1).join(' ');

export const getFirstCategoryName = (categories) => {
  if (!categories || !categories.nodes) return 'Uncategorized';
  return categories?.nodes[0]?.name;
};

export const getFirstCategoryIcon = (categories) => {
  if (!categories || !categories.nodes) return 'Uncategorized';
  return categories?.nodes[0]?.taxonomyCategory?.icon;
};

export const getAuthorName = (post) => {
  if (!post.containerArticle?.masthead?.authorName) return 'Yotta';
  return post.containerArticle?.masthead?.authorName;
};

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPost(id: { eq: $id }) {
      ...BlogSEO
      ...GeneratedWpPost
    }
    wpPartner(id: { eq: $id }) {
      ...PartnerSeo
      ...GeneratedWpPartner
    }
    wp {
      ...GeneratedWp
    }
  }
`;
