import React, { useEffect } from 'react';

import { OneColumn } from '~/components/layouts/OneColumn';
import { TwoColumns } from '~/components/layouts/TwoColumns';

import { Text } from '~/components/flexible/Text';
import { Media } from '~/components/flexible/Media';
import { Stats } from '~/components/flexible/Stats';
import { Logos } from '~/components/flexible/Logos';
import { PulloutCard } from '~/components/flexible/PulloutCard';
import { Grid } from '~/components/flexible/Grid';
import { Form } from '~/components/flexible/Form';
import { Testimonial } from '~/components/flexible/Testimonial';
import { Decoration } from '~/components/flexible/Decoration';
import { TwoColumnAccordion } from '~/components/flexible/TwoColumnAccordion';
import { CaseStudySlider } from '~/components/flexible/CaseStudySlider';
import { BenefitsGrid } from '~/components/flexible/BenefitsGrid';
import { SimpleIconGrid } from '~/components/flexible/SimpleIconGrid';
import { Quote } from '~/components/flexible/Quote';
import { QuoteHeader } from '~/components/flexible/QuoteHeader';
import { TeamMembers } from '~/components/flexible/TeamMembers';

import { CareerLanding } from '~/components/landing/CareerLanding';
import { ResourcesLanding } from '~/components/landing/ResourcesLanding';
import { LegalLanding } from '~/components/landing/LegalLanding';
import { ContactLanding } from '~/components/landing/ContactLanding';
import { DemoLanding } from '~/components/landing/DemoLanding';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Newsletter from '../flexible/Newsletter';
import DemoCTA from '../flexible/DemoCTA';

export const LayoutRenderer = ({ layouts, prefix, startBg, endBg }) => {
  if (!layouts) return null;
  const { sections } = layouts;
  if (!sections) return null;
  return (
    <>
      {sections.map((section, i) => {
        let previousBackground = getSectionBackroundColor(sections, i - 1, true);
        if (!previousBackground) previousBackground = startBg;
        let nextBackground = getSectionBackroundColor(sections, i + 1, false);
        if (!nextBackground) nextBackground = endBg;
        if (section.fieldGroupName.includes('ReusableBlock') && section.reusableBlock) {
          return (
            <LayoutRenderer
              key={i}
              layouts={section.reusableBlock.layouts}
              prefix="ReusableBlock_Layouts_Sections_Section_Layout_"
              startBg={previousBackground}
              nextBg={nextBackground}
            />
          );
        }
        if (section.fieldGroupName.includes('LandingPage')) {
          return <LandingPage key={i} {...section} />;
        }
        return (
          <Section
            num={i}
            prefix={prefix}
            key={i}
            {...section}
            previousBackground={previousBackground}
            nextBackground={nextBackground}
          />
        );
      })}
    </>
  );
};

export const getSectionBackroundColor = (sections, i, isBefore) => {
  if (!sections[i]) return null;
  if (sections[i].backgroundColour) return sections[i].backgroundColour;
  const section = sections[i];
  if (section.fieldGroupName.includes('ReusableBlock') && section.reusableBlock) {
    const layouts = section.reusableBlock.layouts.sections;
    const layout = layouts[isBefore ? layouts.length - 1 : 0];
    return layout.backgroundColour;
  }
  return null;
};

export const Section = ({
  num,
  prefix,
  backgroundImage,
  allowOverflow,
  roundedBottomEdges,
  backgroundColour,
  size,
  backgroundInBox,
  previousBackground,
  nextBackground,
  layout = [],
}) => {
  const classes = ['section space-y-8 md:space-y-14'];
  if (size) {
    classes.push('section-' + size);
  }
  if (previousBackground !== backgroundColour) {
    classes.push('section-top');
  }
  if (nextBackground !== backgroundColour) {
    classes.push('section-bot');
  }
  if (backgroundColour === 'white') {
    classes.push('bg-white');
  } else if (backgroundColour === 'dark') {
    classes.push('bg-dark text-white');
  } else if (backgroundColour === 'dark-alt') {
    classes.push('bg-darkalt text-white');
  }
  if (backgroundInBox) {
    classes.push('container-bound');
  }
  if (roundedBottomEdges) {
    classes.push('about-rounded-masthead');
  }
  if (allowOverflow) {
    classes.push('!overflow-visible');
  }

  const props = {};

  if (backgroundImage) {
    classes.push('section-background-image');
    props.style = {
      backgroundImage: 'url(' + backgroundImage?.localFile?.publicURL + ')',
    };
  }

  return (
    <section data-background={backgroundColour} className={classes.join(' ')} {...props}>
      {layout.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        if (layoutName === 'OneColumn') {
          return <OneColumn num={num} prefix={prefix} key={i} {...layout} />;
        } else if (layoutName === 'TwoColumns') {
          return <TwoColumns num={num} prefix={prefix} key={i} {...layout} />;
        }
        return null;
      })}
    </section>
  );
};

export const ContentRenderer = ({ num, prefix, content = [] }) => {
  if (num === 0) {
    return <AnimatedContentRenderer prefix={prefix} content={content} />;
  }
  if (content.length > 1) {
    return (
      <div className="space-y-6 md:space-y-8">
        <ContentRendererInner prefix={prefix} content={content} />
      </div>
    );
  } else {
    return <ContentRendererInner prefix={prefix} content={content} />;
  }
};

/*
const shouldUseAnimatedRenderer = (content) => {
	for(let layout of content){
		if(layout.style === "masthead" || layout.style === "masthead-large") return true;
	}
	return false;
}
*/

export const AnimatedContentRenderer = ({ prefix, content = [] }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  if (content.length > 1) {
    return (
      <div ref={ref} className="space-y-6 md:space-y-8">
        <AnimatedContentRendererInner controls={controls} prefix={prefix} content={content} />
      </div>
    );
  } else {
    return (
      <div ref={ref}>
        <AnimatedContentRendererInner controls={controls} prefix={prefix} content={content} />
      </div>
    );
  }
};

export const AnimatedContentRendererInner = ({ prefix, content = [], controls }) => (
  <>
    {content.map((layout, i) =>
      layout.fieldGroupName.includes('Media') ? (
        <ContentRendererInner key={'main-content-render-' + i} prefix={prefix} content={[content[i]]} />
      ) : (
        <motion.div
          initial="hidden"
          variants={{
            hidden: { opacity: 0, y: 15 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.6,
                delay: i * 0.2,
              },
            },
          }}
          animate={controls}
          key={'main-content-render-' + i}
        >
          <ContentRendererInner prefix={prefix} content={[content[i]]} />
        </motion.div>
      )
    )}
  </>
);

export const ContentRendererInner = ({ prefix, content = [] }) => {
  return (
    <>
      {content.map((layout, i) => {
        const layoutName = layout.fieldGroupName.replace(prefix, '');
        if (layoutName === 'Text') {
          return <Text key={i} {...layout} />;
        } else if (layoutName === 'Media') {
          return <Media key={i} {...layout} />;
        } else if (layoutName === 'Stats') {
          return <Stats key={i} {...layout} />;
        } else if (layoutName === 'Logos') {
          return <Logos key={i} {...layout} />;
        } else if (layoutName === 'PulloutCard') {
          return <PulloutCard key={i} {...layout} />;
        } else if (layoutName === 'Grid') {
          return <Grid key={i} {...layout} />;
        } else if (layoutName === 'Form') {
          return <Form key={i} {...layout} />;
        } else if (layoutName === 'Newsletter') {
          return <Newsletter key={i} {...layout} />;
        } else if (layoutName === 'DemoForm') {
          return <DemoCTA key={i} {...layout} />;
        } else if (layoutName === 'TwoColumnAccordion') {
          return <TwoColumnAccordion key={i} {...layout} />;
        } else if (layoutName === 'Testimonial') {
          return <Testimonial key={i} {...layout} />;
        } else if (layoutName === 'Decoration') {
          return <Decoration key={i} {...layout} />;
        } else if (layoutName === 'CaseStudySlider') {
          return <CaseStudySlider key={i} {...layout} />;
        } else if (layoutName === 'BenefitsGrid') {
          return <BenefitsGrid key={i} {...layout} />;
        } else if (layoutName === 'SimpleIconGrid') {
          return <SimpleIconGrid key={i} {...layout} />;
        } else if (layoutName === 'Quote') {
          return <Quote key={i} {...layout} />;
        } else if (layoutName === 'QuoteHeader') {
          return <QuoteHeader key={i} {...layout} />;
        } else if (layoutName === 'TeamMembers') {
          return <TeamMembers key={i} {...layout} />;
        } else {
          console.log('IMPLEMENT ', layoutName, layout);
        }
        return null;
      })}
    </>
  );
};

export const LandingPage = (props) => {
  if (props.type === 'career') {
    return <CareerLanding {...props.careerOptions} />;
  } else if (props.type === 'resources') {
    return <ResourcesLanding />;
  } else if (props.type === 'legal') {
    return <LegalLanding {...props.legalOptions} />;
  } else if (props.type === 'contact') {
    return <ContactLanding {...props.contactOptions} />;
  } else if (props.type === 'demo') {
    return <DemoLanding {...props.demoOptions} />;
  }
  return null;
};
