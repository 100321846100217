import React from "react";

export const ArrowRight = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8487 4.86759C11.9459 4.76983 12.0004 4.6376 12.0004 4.49976C12.0004 4.36192 11.9459 4.22969 11.8487 4.13193L8.52522 0.808456C8.42746 0.711282 8.29523 0.656738 8.15739 0.656738C8.01956 0.656738 7.88732 0.711282 7.78957 0.808456C7.74066 0.856959 7.70185 0.914664 7.67536 0.978242C7.64887 1.04182 7.63524 1.11002 7.63524 1.17889C7.63524 1.24777 7.64887 1.31596 7.67536 1.37954C7.70185 1.44312 7.74066 1.50082 7.78957 1.54933L10.7426 4.49715L7.78957 7.43976C7.74066 7.48826 7.70185 7.54597 7.67536 7.60955C7.64887 7.67313 7.63524 7.74132 7.63524 7.8102C7.63524 7.87907 7.64887 7.94727 7.67536 8.01084C7.70185 8.07442 7.74066 8.13213 7.78957 8.18063C7.88732 8.2778 8.01956 8.33235 8.15739 8.33235C8.29523 8.33235 8.42746 8.2778 8.52522 8.18063L11.8487 4.86759ZM0 5.01889H11.4783V3.97541H0V5.01889Z" fill="#2EB6F0"/>
  </svg>
);

export const ArrowLeft = () => (
  <svg className="inline-block relative top-[-1px] mr-3" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.43839e-07 5.49998L5.92308 10.5769L5.92308 6.34613L11 6.34614L11 4.65383L5.92308 4.65383L5.92308 0.423058L4.43839e-07 5.49998Z" fill="#2EB6F0"/>
  </svg>
)

export const FadedCorner = () => (
  <svg className="w-full" viewBox="0 0 413 179" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 179V28C2 13.6406 13.6406 1.99999 28 1.99999H413" stroke="url(#paint0_linear_514_32216)" strokeWidth="3"/>
    <defs>
      <linearGradient id="paint0_linear_514_32216" x1="162.702" y1="80.5907" x2="396.363" y2="139.582" gradientUnits="userSpaceOnUse">
      <stop stopColor="#EAEEF3"/>
      <stop offset="1" stopColor="#EAEEF3" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

export const FadedCornerBottom = () => (
  <svg className="max-w-[100%] max-h-[100%]" width="291" height="225" viewBox="0 0 291 225" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 0V197C2 211.359 13.6406 223 28 223H291" stroke="url(#paint0_linear_743_26381)" strokeWidth="3"/>
    <defs>
      <linearGradient id="paint0_linear_743_26381" x1="115" y1="123.985" x2="286.371" y2="99.8373" gradientUnits="userSpaceOnUse">
        <stop stopColor="#162543" stopOpacity="0.1"/>
        <stop offset="1" stopColor="#EEEEF0" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

export const FadedLine = () => (
  <svg className="max-w-[100%] max-h-[100%]" width="3" height="250" viewBox="0 0 3 250" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{mixBlendMode:"multiply"}}>
      <line x1="1.5" y1="250" x2="1.49999" y2="6.55671e-08" stroke="url(#paint0_linear_543_28170)" strokeWidth="3"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_543_28170" x1="3" y1="259.615" x2="3.00014" y2="9.61538" gradientUnits="userSpaceOnUse">
        <stop offset="0.558778" stopColor="#EAEEF3"/>
        <stop offset="1" stopColor="#EAEEF3" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

export const FadedLeftToRight = () => (
  <svg className="hidden md:inline-block" width="684" height="130" viewBox="0 0 684 130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 0V38.3434C2 52.7028 13.6406 64.3434 28 64.3434H656C670.359 64.3434 682 75.984 682 90.3434V130" stroke="url(#paint0_linear_406_21931)" strokeWidth="3"/>
    <defs>
      <linearGradient id="paint0_linear_406_21931" x1="326" y1="2.0074e-07" x2="325.358" y2="129.989" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1C2439" stopOpacity="0"/>
        <stop offset="0.253412" stopColor="#1C2439" stopOpacity="0.05"/>
        <stop offset="0.732588" stopColor="#1C2439" stopOpacity="0.07"/>
        <stop offset="1" stopColor="#1C2439" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
)

export const FadedRightToLeft = () => (
  <svg className="hidden md:inline-block" width="684" height="130" viewBox="0 0 684 130" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M682 0V38.3434C682 52.7028 670.359 64.3434 656 64.3434H28C13.6406 64.3434 2 75.984 2 90.3434V130" stroke="url(#paint0_linear_406_21932)" strokeWidth="3"/>
    <defs>
      <linearGradient id="paint0_linear_406_21932" x1="358" y1="2.0074e-07" x2="358.642" y2="129.989" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1C2439" stopOpacity="0"/>
        <stop offset="0.253412" stopColor="#1C2439" stopOpacity="0.05"/>
        <stop offset="0.732588" stopColor="#1C2439" stopOpacity="0.07"/>
        <stop offset="1" stopColor="#1C2439" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
)

export const FadedLineCareersTestimonial = () => (
  <svg width="923" height="511" viewBox="0 0 923 511" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{mixBlendMode:"multiply"}}>
      <path d="M0 2L374 2C392.778 2 408 17.2223 408 36V251.45C408 270.228 423.222 285.45 442 285.45H887C905.778 285.45 921 300.673 921 319.45V511" stroke="url(#paint0_linear_510_17231)" strokeWidth="3"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_510_17231" x1="884.5" y1="289" x2="934.238" y2="457.339" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EDEDEF"/>
        <stop offset="1" stopColor="#EDEDEF" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
)

export const FadedLineSimpleIconGrid = () => (
  <svg className="inline-block" width="359" height="530" viewBox="0 0 359 530" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2H75.3064C89.6658 2 101.306 13.6406 101.306 28V79.5457C101.306 93.9051 112.947 105.546 127.306 105.546H331.5C345.859 105.546 357.5 117.186 357.5 131.546L357.5 530" stroke="url(#paint0_linear_510_17102)" strokeOpacity="0.2" stroke-width="3"/>
    <defs>
      <linearGradient id="paint0_linear_510_17102" x1="122.5" y1="403.023" x2="124.058" y2="530.042" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EAEEF3"/>
        <stop offset="1" stopColor="#EAEEF3" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
)

export const AboutLine = () => (
  <svg className="block" width="1251" height="168" viewBox="0 0 1251 168" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{mixBlendMode:"multiply"}}>
      <path d="M1251 2L307.739 2C288.961 2 273.739 17.2223 273.739 36V132C273.739 150.778 258.517 166 239.739 166H-621" stroke="url(#paint0_linear_510_17022)" strokeWidth="3"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_510_17022" x1="910.909" y1="118.809" x2="1251.42" y2="113.464" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EDEDEF"/>
        <stop offset="1" stopColor="#EDEDEF" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

export const ManagingServicesLine = () => (
  <svg className="block" width="1440" height="356" viewBox="0 0 1440 356" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1709 2L1410.2 2C1387.01 2 1368.2 20.804 1368.2 44V263C1368.2 286.196 1349.4 305 1326.2 305H4.44651e-05" stroke="#EEEEF0" strokeWidth="3"/>
    <path d="M0 354.001L524.733 354.001C547.929 354.001 566.733 335.197 566.733 312.001L566.733 266C566.733 242.804 585.537 224 608.733 224L1432 224" stroke="#EEEEF0" strokeWidth="3"/>
  </svg>
);

export const Mail = () => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 0H1.66667C0.75 0 0.00833333 0.75 0.00833333 1.66667L0 11.6667C0 12.5833 0.75 13.3333 1.66667 13.3333H15C15.9167 13.3333 16.6667 12.5833 16.6667 11.6667V1.66667C16.6667 0.75 15.9167 0 15 0ZM15 3.33333L8.33333 7.5L1.66667 3.33333V1.66667L8.33333 5.83333L15 1.66667V3.33333Z" fill="#12203A"/>
  </svg>
)

export const LinkedIn = () => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_543_28128)">
      <path d="M4.03728 16.9999V5.53001H0.224927V16.9999H4.03728ZM2.1316 3.96305C3.46103 3.96305 4.28854 3.0823 4.28854 1.98165C4.26377 0.856185 3.46108 -0.00012207 2.15682 -0.00012207C0.852771 -0.00012207 0 0.856202 0 1.98165C0 3.08235 0.827305 3.96305 2.10671 3.96305H2.13148H2.1316ZM6.1474 16.9999H9.95976V10.5945C9.95976 10.2517 9.98453 9.9093 10.0852 9.66424C10.3608 8.97933 10.9881 8.26995 12.0412 8.26995C13.4208 8.26995 13.9726 9.32178 13.9726 10.8637V16.9998H17.7848V10.4231C17.7848 6.89999 15.9039 5.26073 13.3956 5.26073C11.339 5.26073 10.436 6.41031 9.93439 7.19327H9.95984V5.52977H6.14749C6.19752 6.60604 6.14749 16.9997 6.14749 16.9997L6.1474 16.9999Z" fill="#12203A"/>
    </g>
    <defs>
      <clipPath id="clip0_543_28128">
        <rect width="17.7847" height="17" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export const LinkedInAlt = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_580_20272)">
      <path d="M17.8859 0H0.81831C0.350704 0 0 0.350704 0 0.81831V18.0028C0 18.3535 0.350704 18.7042 0.81831 18.7042H18.0028C18.4704 18.7042 18.8211 18.3535 18.8211 17.8859V0.81831C18.7042 0.350704 18.3535 0 17.8859 0ZM5.49436 15.8986H2.80563V7.01408H5.61127V15.8986H5.49436ZM4.20845 5.84507C3.27324 5.84507 2.57183 5.02676 2.57183 4.20845C2.57183 3.27324 3.27324 2.57183 4.20845 2.57183C5.14366 2.57183 5.84507 3.27324 5.84507 4.20845C5.72817 5.02676 5.02676 5.84507 4.20845 5.84507ZM15.8986 15.8986H13.093V11.5732C13.093 10.5211 13.093 9.23521 11.6901 9.23521C10.2873 9.23521 10.0535 10.4042 10.0535 11.5732V16.0155H7.24789V7.01408H9.93662V8.1831C10.2873 7.48169 11.2225 6.78028 12.5084 6.78028C15.3141 6.78028 15.7817 8.6507 15.7817 10.9887V15.8986H15.8986Z" fill="#2EB6F0"/>
    </g>
    <defs>
      <clipPath id="clip0_580_20272">
        <rect width="18.7042" height="18.7042" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export const Twitter = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.41622 14.0029C11.8893 14.0029 15.456 8.65274 15.456 4.02914C15.456 3.89704 15.456 3.69888 15.456 3.56678C16.1166 3.03837 16.711 2.4439 17.2394 1.71734C16.5789 1.98154 15.9184 2.1797 15.1918 2.24575C15.9184 1.78339 16.4468 1.12288 16.711 0.264206C16.0505 0.660515 15.2579 0.990772 14.4653 1.12288C13.8048 0.46236 12.88 0 11.8893 0C9.97377 0 8.38854 1.58524 8.38854 3.50073C8.38854 3.76493 8.38854 4.02914 8.45459 4.29335C5.54832 4.22729 2.90627 2.84021 1.18893 0.726566C0.924721 1.25498 0.726566 1.84944 0.726566 2.50996C0.726566 3.76493 1.32103 4.82176 2.3118 5.41622C1.71734 5.41622 1.18893 5.21807 0.660515 4.95386C0.660515 4.95386 0.660515 4.95386 0.660515 5.01991C0.660515 6.73725 1.84944 8.12433 3.50073 8.45459C3.23652 8.52064 2.90627 8.58669 2.57601 8.58669C2.37785 8.58669 2.11365 8.58669 1.91549 8.52064C2.37785 9.90772 3.63283 10.9645 5.21807 10.9645C4.02914 11.8893 2.50996 12.4837 0.858669 12.4837C0.594463 12.4837 0.264206 12.4837 0 12.4177C1.58524 13.4085 3.43468 14.0029 5.41622 14.0029Z" fill="#12203A"/>
  </svg>
)

export const ChevronRight = () => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13L7 7L0.999999 1" stroke="#1D2B36" strokeWidth="2"/>
  </svg>
)

export enum IconType {
  None = "none",
  ArrowRight = "arrowRight",
}

const IconMap = {
  arrowRight: ArrowRight,
};

interface IconProps {
  type: string;
}

export const Icon = ({ type }: IconProps) => {
  if ((type === IconType.None || !type)) return null;
  return IconMap[type]();
};
