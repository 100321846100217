import React, { useState } from 'react';
import { useField } from 'formik';
import { ErrorMessage } from '~/components/forms/fields/InputField';

export default function InputField(props) {
  const { label, disabled, options, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col justify-center group !border-error`}>
      <div className="relative">
        <label
          className={`uppercase t-14 text-navy font-medium pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10
          ${disabled && 'text-opacity-30 group-hover:-translate-y-1/2'}
          ${(meta.value?.length > 0 || isInvalid) && '!-translate-y-10'}`}
        >
          {label}
        </label>
        <select
          className={`select cursor-pointer bg-transparent text-navy border-b hover:border-opacity-100 outline-none ${
            disabled && 'opacity-30 hover:border-opacity-30'
          } focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 px-0 appearance-none w-full border-b-navy border-opacity-30 `}
          {...field}
          {...other}
          defaultValue=""
        >
          {options?.length > 0 &&
            options.map((option) => (
              <option key={option.value} value={option.value} dangerouslySetInnerHTML={{ __html: option.label }} />
            ))}
        </select>
        {isInvalid && <ErrorMessage error={meta.error} />}
      </div>
    </div>
  );
}
