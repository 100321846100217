import React from "react";
import { ContentRenderer } from "~/components/layouts/LayoutRenderer";

export const OneColumn = (props) => {
	const { num, prefix, content, type, centerSize, leftSize } = props;
	const containerClasses = ["container"];
	const leftContainerClasses = [];
	if(type === "center"){
		containerClasses.push("text-center");
		if(centerSize === "narrow"){
			containerClasses.push("max-w-[680px]")
		} else if(centerSize === "860"){
			containerClasses.push("max-w-[860px]")
		}  else if(centerSize === "920"){
			containerClasses.push("max-w-[920px]")
		} else if(centerSize === "1040"){
			containerClasses.push("max-w-[1040px]")
		} else if(centerSize === "1160"){
			containerClasses.push("max-w-[1160px]")
		} else {
			containerClasses.push("max-w-[760px]")
		}
	} else if(type === "left"){
		if(leftSize === "865"){
			leftContainerClasses.push("max-w-[865px]");
		} else if(leftSize === "740"){
			leftContainerClasses.push("max-w-[740px]");
		} else if(leftSize === "656"){
			leftContainerClasses.push("max-w-[656px]");
		} else if(leftSize === "610"){
			leftContainerClasses.push("max-w-[610px]");
		} else if(leftSize === "578"){
			leftContainerClasses.push("max-w-[578px]");
		} else if(leftSize === "450") {
			leftContainerClasses.push("max-w-[450px]");
		} else {
			leftContainerClasses.push("max-w-[520px]");
		}
	}
	return (
		<div className={containerClasses.join(" ")}>
			{type === 'left' ? (
				<div className={leftContainerClasses.join(" ")}>
					<ContentRenderer num={num} content={content} prefix={prefix+"OneColumn_Content_"} />
				</div>
			)	 : (
				<ContentRenderer num={num} content={content} prefix={prefix+"OneColumn_Content_"} />
			)}
		</div>
	);
}