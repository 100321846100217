import React, { useState, useEffect } from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { ArrowLink } from '~/components/elements/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { ArrowRight } from '~/components/elements/Icon';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

export const TwoColumnAccordion = ({ items, flipColumns, style, curvedLine, singleImage }) => {
	const [active, setActive] = useState(0);
	const imageClasses = [];
	const colAClasses = ['flex-1 relative'];
	const colBClasses = ['overflow-swiper'];
	const containerClasses = ['md:flex'];
	const headingClasses = [];

	const [swiper, set_swiper] = useState({});

	useEffect(() => {
		if (swiper.on) {
			swiper.on('slideChange', function () {
				setActive(swiper.activeIndex);
			});
		}
	}, [swiper]);

	useEffect(() => {
		if (swiper.slideTo && swiper.activeIndex !== active) {
			if (!singleImage) {
				swiper.slideTo(active);
			}
		}
	}, [swiper, active]);

	if (style === 'simple') {
		containerClasses.push('items-center');
		colBClasses.push('flex-1 xl:w-[51%] md:pr-20');
	} else if (style === 'simple-with-overflow') {
		containerClasses.push('items-center');
		colBClasses.push('flex-1 xl:w-[51%] md:pr-20');
	} else if (style === 'box-with-arrows') {
		containerClasses.push('items-start');
		colAClasses.push('md:mt-20');
		colBClasses.push(
			'md:w-[50%] xl:w-[56%] px-8 py-12 two-column-accordion-shadow bg-white text-[#314351] md:px-[94px] md:py-[70px]'
		);
	} else if (style === 'box-with-image-overflow') {
		containerClasses.push('items-center');
		imageClasses.push('');
		colBClasses.push(
			'md:w-[50%] xl:w-[56%] px-8 py-12 two-column-accordion-shadow bg-white rounded-[6px] text-[#314351] md:px-[94px] md:py-[70px]'
		);
		headingClasses.push('!text-navy');
	} else if (style === 'left-larger') {
		containerClasses.push('items-center');
		colBClasses.push('flex-1 xl:w-[52%] md:pr-12');
	} else {
		colBClasses.push('xl:w-[42%] md:pl-4');
		imageClasses.push('lg:mt-[-80%] lg:mb-[-10%] lg:w-[110%] relative left-[-5%]');
	}
	if (flipColumns) {
		containerClasses.push('flex-row-reverse');
	}

	const previousSlide = () => {
		if (active !== 0) {
			setActive(active - 1);
		}
	};
	const nextSlide = () => {
		if (active !== items.length - 1) {
			setActive(active + 1);
		}
	};

	let lineStyles = 'curved-line absolute !m-0 border-navy border-opacity-10 pointer-events-none';

	return (
		<div className={containerClasses.join(' ')}>
			<div className={colAClasses.join(' ')}>
				{curvedLine === 'implementation' ? (
					<div
						className={`${lineStyles} -top-12 bottom-0 right-[calc(100%-22px)] h-[500%] w-48 line-fade-left border-t-[3px] border-r-[3px] hidden md:block`}
						style={{ borderRadius: '0 48px 0 0' }}
					/>
				) : curvedLine === 'alloy' || curvedLine === 'horizons' ? (
					<>
						<div
							className={`${lineStyles} line-fade-down top-[-40%] bottom-0 left-[19px] h-[190%] w-48 border-t-[3px] border-l-[3px] hidden lg:block`}
							style={{ borderRadius: '26px 0 0 0' }}
						/>
						<div
							className={`${lineStyles} top-[-100.3%] bottom-0 left-[211px] h-[61.2%] w-10 border-b-[3px] border-r-[3px] hidden lg:block`}
							style={{ borderRadius: '0 0 26px 0' }}
						/>
						<div
							className={`${lineStyles}  top-[-107.7%] bottom-0 left-[248px] h-[26px] w-full border-t-[3px] border-l-[3px] hidden lg:block`}
							style={{ borderRadius: '26px 0 0 0' }}
						/>
						{/* <div className={`${lineStyles} -top-48 bottom-0 left-[19px] h-[calc(100%+250px)] w-32 border-t-[3px] border-l-[3px] hidden md:block`} style={{borderRadius: '26px 0 0 0'}} /> */}
					</>
				) : curvedLine === 'home' ? (
					<>
						<div
							className={`${lineStyles} -top-16 bottom-0 left-[calc(-138px)] h-[150%] w-40 border-b-[3px] border-r-[3px] hidden md:block`}
							style={{ borderRadius: '0 0 26px 0' }}
						/>
						<div
							className={`${lineStyles} line-fade-right top-[-90px] bottom-0 left-[19px] h-[26px] w-1/2 border-t-[3px] border-l-[3px] hidden md:block`}
							style={{ borderRadius: '26px 0 0 0' }}
						/>
					</>
				) : (
					''
				)}
				<ul className="hidden md:block space-y-10 ml-4">
					{items.map(({ tabTitle, tabDescription }, i) => (
						<li
							onClick={() => {
								setActive(i);
							}}
							key={i}
							className={`cursor-pointer hover:opacity-50 transition-opacity duration-500 relative`}
						>
							<div
								className={`absolute rounded-[100%] bg-sky transition-transform duration-500 inline-block${
									i === active ? ' transform scale-[1.6]' : ''
								} w-[10px] h-[10px] top-0 left-0 top-[11px]`}
							></div>
							<AnimatePresence>
								{i === active && (
									<motion.div
										initial={{ opacity: 0, y: -15 }}
										animate={{ opacity: 0.1, y: 0 }}
										exit={{ opacity: 0, y: 15 }}
										transition={{ duration: 0.55 }}
										className="rounded-[100%] bg-sky block w-[36px] h-[36px] top-[-2px] left-[-13px] absolute"
									></motion.div>
								)}
							</AnimatePresence>
							<div className={`pl-20 ${i !== active ? ' opacity-40' : ''}`}>
								<h6 className="text-[22px] font-medium">{tabTitle}</h6>
								<AnimatePresence>
									{i === active && (
										<motion.div
											className="overflow-hidden"
											key="content"
											initial="collapsed"
											animate="open"
											exit="collapsed"
											variants={{
												open: { opacity: 1, height: 'auto' },
												collapsed: { opacity: 0, height: 0 },
											}}
											transition={{ duration: 0.3 }}
										>
											{tabDescription && <p className="t-18 pt-2">{tabDescription}</p>}
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						</li>
					))}
				</ul>
				<ul className="mb-6 md:hidden grid gap-2 grid-cols-2 flex text-center">
					{items.map(({ tabTitle, tabDescription }, i) => (
						<li
							onClick={() => {
								setActive(i);
							}}
							key={i}
							className={`flex-1 cursor-pointer font-medium px-2  ${
								i === active ? 'bg-sky text-white' : 'bg-white text-navy '
							} rounded-medium py-4 t-14`}
						>
							{tabTitle}
						</li>
					))}
				</ul>
			</div>
			<div className={colBClasses.join(' ')}>
				<Swiper
					onInit={(ev) => {
						set_swiper(ev);
					}}
					spaceBetween={0}
					slidesPerView={1}
				>
					{items.map(({ image, heading, content, link }, i) => {
						if (singleImage && i !== 0) return null;
						return (
							<SwiperSlide key={i}>
								<div key={`accordion-${i}`} className="relative space-y-4">
									<div className={imageClasses.join(' ')}>
										<Image image={image} />
									</div>
									{heading && <h4 className={`t-32 ${headingClasses}`}>{heading}</h4>}
									{content && <p className="t-18 max-w-[430px]">{content}</p>}
									{link && (
										<div className="pt-2">
											<ArrowLink link={link} />
										</div>
									)}
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>

				{style === 'box-with-arrows' && (
					<div className="flex space-x-2.5 mt-9">
						<button
							className={`w-[52px] h-[52px] rounded-[100%] bg-blue hover:bg-navy transition-colors duration-300 ease-in-out flex items-center justify-center ${
								active === 0 && 'bg-navy bg-opacity-20 hover:bg-opacity-20'
							}`}
							onClick={previousSlide}
						>
							<div className="w-[18px] fw-icon icon-white transform rotate-180">
								<ArrowRight />
							</div>
						</button>
						<button
							className={`w-[52px] h-[52px] rounded-[100%] bg-blue hover:bg-navy transition-colors duration-300 ease-in-out flex items-center justify-center ${
								active === items.length - 1 && 'bg-navy bg-opacity-20 hover:bg-opacity-20'
							}`}
							onClick={nextSlide}
						>
							<div className="w-[18px] fw-icon icon-white">
								<ArrowRight />
							</div>
						</button>
					</div>
				)}
			</div>
		</div>
	);
};
