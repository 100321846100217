import React from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { FadedLine } from '~/components/elements/Icon';
import { ArrowLink } from '~/components/elements/Typography';

export const removeArticleFlexiblePrefix = (string) =>
	string.replace('Post_Containerarticle_Content_', '').replace('Partner_Containerarticle_Content_', '');

export const ArticleFlexible = ({ post }) => {
	const content = post.containerArticle?.content;
	if (!content) return null;
	return (
		<div className="space-y-6 md:space-y-12">
			{content.map((layout, i) => {
				const layoutName = removeArticleFlexiblePrefix(layout.fieldGroupName);
				if (layoutName === 'Wysiwyg') {
					return <Wysiwyg key={i} {...layout} />;
				} else if (layoutName === 'Quote') {
					return <Quote key={i} {...layout} />;
				} else if (layoutName === 'Media') {
					return <Media key={i} {...layout} />;
				} else if (layoutName === 'Article') {
					const { article } = layout;
					return <Article key={i} {...layout} />;
				}
			})}
		</div>
	);
};

export const Wrapper = ({ children }) => <div className="mx-auto max-w-[670px]">{children}</div>;
export const WrapperWide = ({ children }) => <div className="mx-auto max-w-[810px]">{children}</div>;

export const Wysiwyg = ({ wysiwyg }) => (
	<Wrapper>
		<div className="prose article" dangerouslySetInnerHTML={{ __html: wysiwyg }}></div>
	</Wrapper>
);

export const Quote = ({ name, text, title }) => (
	<Wrapper>
		<blockquote className="relative">
			<p className="t-22 mb-3 md:mb-6">{text}</p>
			<h6 className="t-18">
				<strong className="block mb-[-2px]">{name}</strong>
				<span className="t-15">{title}</span>
			</h6>
		</blockquote>
	</Wrapper>
);

export const Media = ({ image }) => (
	<WrapperWide>
		<Image image={image} />
	</WrapperWide>
);

export const Article = ({ article }) => (
	<WrapperWide>
		<div className="bg-blue text-white rounded-large md:flex p-[16px] md:p-[32px] items-center">
			<div className="flex-1 pb-8 md:p-[12px]">
				<h6 className="uppercase font-medium text-[14px] opacity-40">You might also like</h6>
				<h3 className="t-32 pt-3 pb-2">{article.title}</h3>
				{article.link && (
					<div className="pt-2 text-sky font-medium">
						<ArrowLink link={{ url: article.link, title: 'Read more' }} />
					</div>
				)}
			</div>
			<div className="md:w-[30%] lg:w-[42%]">
				<div className="rounded-large overflow-hidden">
					<Image image={article.featuredImage.node} />
				</div>
			</div>
		</div>
	</WrapperWide>
);
