import React from 'react';
import Image from '~/components/elements/Image';
import Marquee from 'react-fast-marquee';

export const Logos = ({ heading, logos, style }) => (
  <>
    {heading && <h6 className="mb-6 font-medium text-center t-22 lg:mb-10">{heading}</h6>}
    {style === 'grid' ? <LogosGrid logos={logos} /> : <LogosMarquee logos={logos} />}
  </>
);

export const Logo = ({ logo, margin }) => (
  <div className={` bg-white rounded-medium w-full flex ${margin ? 'mx-2 md:mx-4' : ''}`}>
    <Image image={logo} className="w-[115px] md:w-[166px]" />
  </div>
);

export const LogosGrid = ({ logos }) => (
  <div className="grid grid-cols-3 gap-4 md:grid-cols-4 lg:grid-cols-6 lg:gap-10 lg:gap-y-6">
    {logos.map((logo, i) => (
      <Logo logo={logo} key={i} />
    ))}
  </div>
);

export const LogosMarquee = ({ logos }) => (
  <div className="marquee-gradient">
    <Marquee gradient={false} speed={60}>
      {logos.map((logo, i) => (
        <Logo logo={logo} margin={true} key={i} />
      ))}
    </Marquee>
  </div>
);
