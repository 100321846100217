import React, { useContext } from 'react';
import Image from '~/components/elements/Image';
import { PageContext } from '~/templates/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';

export const LegalLanding = ({ pageTitle, contentTitle, lastUpdated, content }) => {
	const { page } = useContext(PageContext);

	const legalMenuQuery = useStaticQuery(graphql`
		query {
			wp {
				footer {
					globalFooter {
						footer {
							legalMenu {
								fieldGroupName
								links {
									target
									title
									url
								}
							}
						}
					}
				}
			}
		}
	`);

	const legalMenu = legalMenuQuery.wp.footer.globalFooter.footer.legalMenu;

	return (
		<>
			<section className="container section section-top section-header text-center">
				<h1 className="t-52">{pageTitle}</h1>
				<p className="text-center pt-4">Last Updated: {lastUpdated}</p>
			</section>
			<section className="bg-white container-bound section section-top section-bot">
				<div className="container max-w-[920px]">
					<div className="pt-8 t-18 prose" dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			</section>
		</>
	);
};
