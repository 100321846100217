import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '~/components/forms/fields/InputField';
import SelectField from '~/components/forms/fields/SelectField';
import CheckboxField from '~/components/forms/fields/CheckboxField';
import { Button } from '~/components/elements/Typography';
import { Link } from 'gatsby';

const validationSchema = Yup.object({
  demo_type: Yup.string(),
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  companyName: Yup.string().required('Required'),
  companyUrl: Yup.string().required('Required'),
  jobTitle: Yup.string().required('Required'),
  employees: Yup.string().required('Required'),
  industry: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  legal: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export default function ContactForm() {
  return <ContactFormInner />;
}

export function ContactFormInner() {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');

  const [isSubmitted, setIsSubmitted] = useState(false);
  2;

  useEffect(() => {
    if (typeof location === undefined) return null;
    const params = new URLSearchParams(location.search);
    setEmail(params.get('email'));
  }, []);

  return (
    <Formik
      initialValues={{
        demo_type: 'alloy',
        name: '',
        email: email || '',
        companyName: '',
        companyUrl: '',
        jobTitle: '',
        employees: '',
        industry: '',
        country: '',
        legal: false,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (setIsSubmitted) {
          setIsSubmitted(true);
        }
      }}
    >
      {(props) => (
        <Form className="" id="demo-request-form">
          <div className="">
            <div className="space-y-12 bg-white lg:min-h-[856px] flex flex-col items-center  demo-shadow-form rounded-[7px] overflow-hidden px-12 py-16 lg:px-[100px] lg:py-[100px]">
              {isSubmitted ? (
                <div className="flex flex-col items-center justify-center w-full py-12 my-auto">
                  <h2 className="t-32">Request received</h2>
                  <div className="mt-8">You will receive an email confirmation shortly </div>
                  <Link to="/">
                    <Button className="!px-[44px] !py-4 mt-8">Return home</Button>
                  </Link>
                </div>
              ) : (
                <>
                  <div className="space-y-10">
                    <div className="space-x-3 font-medium t-32">
                      Book your free demo of
                      <Field
                        as="select"
                        name="demo_type"
                        className={`select select-narrow ml-2 text-sky font-medium inline-block border-b-2 border-b-sky py-0 md:py-1 -mt-2 outline-none cursor-pointer appearance-none rounded-[0]  `}
                      >
                        <option value="alloy">Alloy</option>
                        <option value="horizons">Horizons</option>
                      </Field>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-7 md:gap-y-12">
                      <InputField name="name" label="Full Name" />
                      <InputField name="email" label="Email Address" value="test" />
                      <InputField name="companyName" label="Company Name" />
                      <InputField name="companyUrl" label="Company URL" />
                      <InputField name="jobTitle" label="Job Title" />
                      <SelectField
                        name="employees"
                        label="No. of Employees"
                        options={[
                          {
                            value: '',
                            label: '',
                          },
                          {
                            value: '<10',
                            label: '<10',
                          },
                          {
                            value: '10-49',
                            label: '10-49',
                          },
                          {
                            value: '50-249',
                            label: '50-249',
                          },
                          {
                            value: '250-1000',
                            label: '250-1000',
                          },
                          {
                            value: '>1000',
                            label: '>1000',
                          },
                        ]}
                      />
                      <SelectField
                        name="industry"
                        label="Your Industry"
                        options={[
                          {
                            value: '',
                            label: '',
                          },
                          {
                            value: 'Oil/Gas',
                            label: 'Oil/Gas',
                          },
                          {
                            value: 'Local Authority',
                            label: 'Local Authority',
                          },
                          {
                            value: 'Consultant / Contractor ',
                            label: 'Consultant / Contractor ',
                          },
                          {
                            value: 'Facilities management',
                            label: 'Facilities management',
                          },
                          {
                            value: 'EV',
                            label: 'EV',
                          },
                        ]}
                      />
                      <SelectField
                        name="country"
                        label="Country"
                        options={[
                          {
                            value: '',
                            label: '',
                          },
                          {
                            value: 'USA',
                            label: 'USA',
                          },
                          {
                            value: 'England',
                            label: 'England',
                          },
                          {
                            value: 'Scotland',
                            label: 'Scotland',
                          },
                          {
                            value: 'Wales',
                            label: 'Wales',
                          },
                          {
                            value: 'Northern Ireland',
                            label: 'Northern Ireland',
                          },
                          {
                            value: 'Australia',
                            label: 'Australia',
                          },
                        ]}
                      />
                      <div className="md:col-span-2">
                        <CheckboxField
                          name="legal"
                          label="I accept Yotta’s Privacy Policy and agree to receive promotional messages from Yotta about its products and services. You can unsubscribe at any time by clicking on the link at the bottom of our emails."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button type="submit" className="!px-[44px] !py-4">
                      Request Demo
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {errorMessage?.length > 0 && <div className="mt-4" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
        </Form>
      )}
    </Formik>
  );
}
