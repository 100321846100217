import React from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
import Footer from '~/components/global/Footer';
import Header from '~/components/global/Header';
import Cookie from '../components/global/Cookie';

export const PageContext = React.createContext();

export default function Layout({ wp, page, post, children }) {
  const seo = useSiteInfoAssets();
  const backgroundClasses = ['page overflow-hidden'];
  if (page && page?.postTypePage?.darkBackground) {
    backgroundClasses.push('bg-dark text-white');
  } else {
    backgroundClasses.push('bg-default');
  }
  let hideHeaderAndFooter = false;
  if (page && page?.postTypePage?.hideHeaderAndFooter) {
    hideHeaderAndFooter = page?.postTypePage?.hideHeaderAndFooter;
  }

  return (
    <PageContext.Provider value={{ page: page ? page : post }}>
      <SEOContext.Provider value={{ global: seo }}>
        {!hideHeaderAndFooter && <Header data={wp?.header} social={wp.footer.globalFooter.footer.social} />}
        <div className={backgroundClasses.join(' ')}>
          <main>{children}</main>
          {!hideHeaderAndFooter && <Footer data={wp?.footer} />}
        </div>

        <Cookie />
      </SEOContext.Provider>
    </PageContext.Provider>
  );
}
