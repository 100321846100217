import React from 'react';
import Image from '~/components/elements/Image';

export const Testimonial = (props) => {
	const { logo, text, name, jobTitle } = props;

	return (
		<div data-content="Testimonial">
			{logo && (
				<div className="pb-3">
					<div className={`invert-on-dark w-[160px] mx-auto mb-1`}>
						<Image image={logo} />
					</div>
				</div>
			)}
			{text && <div className="t-28 pb-4 lg:pt-6 lg:pb-8" dangerouslySetInnerHTML={{ __html: text }} />}
			{name && (
				<div className="pt-2">
					<strong>{name},</strong> {jobTitle}
				</div>
			)}
		</div>
	);
};
