import React, { useState } from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { ArrowRight } from '~/components/elements/Icon';
import { Dialog } from '@headlessui/react';

export const Button = ({ link, color, children, className, type }) => {
  const buttonClasses = ['button group'];

  if (color === 'grey') {
    buttonClasses.push('button-grey');
  } else if (color === 'black') {
    buttonClasses.push('button-black');
  } else if (color === 'outline-white') {
    buttonClasses.push('button-outline-white');
  }

  if (className) {
    buttonClasses.push(className);
  }

  return (
    <button type={type} className={buttonClasses.join(' ')}>
      {children}
    </button>
  );
};

export const ArrowLink = ({ link, visualOnly = false, flip = false }) => {
  const linkClasses = ['arrowlink group'];

  return (
    <MaybeLink link={visualOnly ? false : link} className={linkClasses.join(' ')}>
      {link.title}
      <div className="transition-transform duration-300 ease-in-out transform group-hover:translate-x-2">
        <ArrowRight />
      </div>
    </MaybeLink>
  );
};

export const MaybeLink = ({ link, children, className }) => {
  if (link) {
    if (link.url.includes('tel:+') || link.url.includes('mailto:')) {
      return (
        <a href={link.url} className={className}>
          {children}
        </a>
      );
    } else if (link.url.includes('player.vimeo')) {
      return (
        <DialogLink className={className} link={link}>
          {children}
        </DialogLink>
      );
    } else {
      return (
        <Link className={className} to={link.url}>
          {children}
        </Link>
      );
    }
  } else {
    return <div className={className}>{children}</div>;
  }
};

export const DialogLink = ({ link, children, className }) => {
  let [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative mx-auto">
            <iframe
              className="max-w-[90%] max-h-[70%] mx-auto"
              src={link.url}
              width="700"
              height="500"
              frameborder="0"
              scrolling="no"
              webkitallowfullscreen=""
              mozallowfullscreen=""
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </Dialog>
      <div onClick={() => setIsOpen(true)} className={`cursor-pointer ${className}`}>
        {children}
      </div>
    </>
  );
};
