import React from 'react';
import { FadedLineCareersTestimonial } from '~/components/elements/Icon';

export const Quote = ({ text, name, jobTitle }) => (
	<div className="text-center lg:text-left relative py-12 lg:pt-8 lg:pb-20">
		<div className="absolute hidden lg:block left-[-150px] bottom-[-220px]">
			<FadedLineCareersTestimonial />
		</div>
		<div className="lg:max-w-[720px] lg:ml-[34%]">
			<p className="t-32 leading-[34px] mb-6">{text}</p>
			<h6 className="t-22">
				<strong className="block">{name}</strong>
				<span className="t-16 font-normal">{jobTitle}</span>
			</h6>
		</div>
	</div>
);
