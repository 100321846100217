import React from 'react';
import Image from '~/components/elements/Image';
import { formatArticleDate, getFirstCategoryName, getAuthorName } from '../../templates/article';
import { Link } from 'gatsby';
import { ArrowLeft } from '~/components/elements/Icon';
import { Button } from '~/components/elements/Typography';

export const Masthead = ({ post }) => {
  if (post.nodeType === 'Partner') {
    return <PartnerHeader post={post} masthead={post.containerArticle.masthead} />;
  } else {
    return <ArticleHeader post={post} masthead={post.containerArticle.masthead} />;
  }
};

export const PartnerHeader = ({ post, masthead }) => (
  <div className="container text-center section section-top section-bot section-header">
    <div className="text-center">
      <Link className="inline-block font-bold text-sky" to="/partners/">
        <ArrowLeft />
        Go back
      </Link>
    </div>
    <div className="max-w-[280px] p-8 mx-auto bg-white rounded-medium shadow-heavy mt-8 mb-12">
      <Image className="max-h-[32px]" image={masthead.logo} />
    </div>
    <h1 className="t-40 mb-4">{post.title}</h1>
    <p className="t-18 max-w-[580px] mx-auto">{masthead.summary}</p>
  </div>
);

export const ArticleHeader = ({ post, masthead }) => (
  <div className="container section section-top section-bot section-header">
    <div className="space-y-4 md:space-y-0 md:flex items-center">
      <div className="flex-1 space-y-3">
        {masthead.type === 'default' ||
          (masthead.type === 'download' && <h6 className="t-subheading">{getFirstCategoryName(post.categories)}</h6>)}
        {masthead.type === 'case-study' && masthead.logo && (
          <div className="pb-4">
            <Image className="max-h-[38px]" imageClasses="" image={masthead.logo} />
          </div>
        )}
        <h1 className="t-40">{masthead.title ? masthead.title : post.title}</h1>
        {masthead.type === 'default' && (
          <div className="t-18">
            Written by {getAuthorName(post)} · {formatArticleDate(post.date)}
          </div>
        )}
        {masthead.type === 'case-study' && <p className="t-18 pt-2 opacity-80 max-w-[490px]">{masthead.summary}</p>}
        {masthead.type === 'download' && masthead.file && (
          <div className="pt-2">
            <Button>
              <a href={masthead.file?.localFile.publicURL}>Download now</a>
            </Button>
          </div>
        )}
        {masthead.type === 'event' && <ArticleHeaderEvents event={masthead.event} />}
      </div>
      <div className="md:w-[35%] lg:w-[45%] xl:w-[55%] md:pl-8">
        <div className="rounded-large overflow-hidden">
          <Image image={post.featuredImage?.node} />
        </div>
        {masthead.videoEmbed && <span>Video embed play button over image.</span>}
      </div>
    </div>
  </div>
);

export const ArticleHeaderEvents = ({ event }) => (
  <div className="space-y-2 md:space-y-0 md:flex md:space-x-6 lg:space-x-10 md:pt-8">
    {event.date && (
      <div>
        <h6 className="t-14 opacity-40 font-medium">{months[new Date(event.date).getMonth()]}</h6>
        <div className="t-32">{dateWithNth(new Date(event.date).getDate())}</div>
      </div>
    )}
    {event.time && (
      <div>
        <h6 className="t-14 opacity-40 font-medium">Start time</h6>
        <div className="t-32">{event.time}</div>
      </div>
    )}
    {event.location && (
      <div>
        <h6 className="t-14 opacity-40 font-medium">Location</h6>
        <div className="t-32">{event.location}</div>
      </div>
    )}
  </div>
);

const dateWithNth = (d) => {
  return d + '' + nth(d);
};

const nth = function (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
