import React from 'react';
import Image from '~/components/elements/Image';
import { MaybeLink, ArrowLink, Button } from '~/components/elements/Typography';
import { FadedCornerBottom } from '~/components/elements/Icon';

export const Grid = ({ items, style }) => {
  const containerClasses = ['grid grid-cols-1'];
  const itemClasses = ['relative'];
  const headingClasses = [];
  const contentClasses = [];
  const imageClasses = ['image-container rounded-medium'];
  let linkType = 'arrow';
  if (style === 'feature') {
    itemClasses.push('space-y-3 bg-white bg-adapt-to-dark rounded-large overflow-hidden p-8 shadow-large');
    containerClasses.push('md:grid-cols-2 lg:grid-cols-3 gap-6');
    headingClasses.push('t-16 pt-8 font-medium');
    contentClasses.push('t-15');
    imageClasses.push('w-[50px] bg-[#EEEEF0] bg-opacity-10');
  } else if (style === 'pages') {
    itemClasses.push('space-y-4 md:pl-[40px] md:pr-[15px] md:pb-[32px]');
    imageClasses.push('pb-[10px] md:ml-[-40px] md:mr-[-15px] md:pb-[22px] image-cover-container');
    headingClasses.push('t-22 font-medium');
    contentClasses.push('t-16 opacity-80');
    containerClasses.push('md:grid-cols-2 lg:grid-cols-3 gap-7');
  } else if (style === 'support') {
    itemClasses.push('space-y-4 text-center bg-dark py-8 px-8 xl:py-14 xl:px-20 rounded-large border border-lwhite');
    imageClasses.push('w-[60px] mx-auto pb-4 xl:pb-6');
    headingClasses.push('t-22 font-medium');
    contentClasses.push('t-16');
    containerClasses.push('md:grid-cols-2 gap-5 xl:gap-y-12');
    linkType = 'button';
  } else if (style === 'centered') {
    itemClasses.push('space-y-4 text-center');
    imageClasses.push('h-[250px] md:h-[300px] pb-6 flex flex-col justify-center my-6');
    headingClasses.push('t-22 font-medium');
    contentClasses.push('t-16');
    containerClasses.push('md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-12 xl:gap-20 gap-y-16');
  } else if (style === 'image-on-left') {
    itemClasses.push('relative pl-[80px] xl:pl-[140px]');
    imageClasses.push('w-[60px] xl:w-[90px] absolute left-0');
    headingClasses.push('t-22 font-medium mb-2');
    contentClasses.push('t-16');
    containerClasses.push('gap-y-8 xl:gap-y-16');
  } else if (style === 'default-large') {
    itemClasses.push('space-y-2 md:space-y-4');
    headingClasses.push('t-32 font-medium');
    if (!items[0] && items[0].subheading) {
      headingClasses.push('pt-4 md:pt-6');
    }
    contentClasses.push('t-16');
    containerClasses.push('md:grid-cols-2 gap-5 gap-y-12 lg:gap-20');
  } else if (style === 'partners') {
    imageClasses.push('bg-white py-5 px-6 max-w-[250px] h-[100px] partners-image-fix flex flex-col justify-center');
    itemClasses.push('space-y-2 md:space-y-4');
    headingClasses.push('t-32 font-medium pt-4 md:pt-6');
    contentClasses.push('t-16');
    containerClasses.push('md:grid-cols-2 gap-5 gap-y-12 lg:gap-20');
  } else {
    itemClasses.push('space-y-4');
    headingClasses.push('t-22 font-medium pt-3.5');
    contentClasses.push('t-16');
    containerClasses.push('md:grid-cols-2 gap-5 gap-y-12');
  }
  if (style !== 'centered') {
    imageClasses.push('overflow-hidden');
  }
  return (
    <div data-content="Grid" className={containerClasses.join(' ')}>
      {items.map(({ image, heading, subheading, content, link, shadowOffsetX, shadowOffsetY }, i) => {
        return (
          <MaybeLink
            link={link}
            key={i}
            className={`${itemClasses.join(' ')} ${
              link && style !== 'support' && style !== 'partners' && 'zoom-inner-image'
            } `}
          >
            {style === 'pages' ? (
              <div className="hidden md:block absolute bottom-0 left-0 z-[-1]">
                <FadedCornerBottom />
              </div>
            ) : null}
            <div className={imageClasses.join(' ')}>
              <Image
                {...shadowOffset(shadowOffsetX, shadowOffsetY)}
                image={image}
                className={`${style === 'pages' && 'rounded-medium'}`}
              />
            </div>
            {subheading && <h4 className="pt-6 t-subheading">{subheading}</h4>}
            <h4 className={headingClasses.join(' ')}>{heading}</h4>
            <p className={contentClasses.join(' ')} dangerouslySetInnerHTML={{ __html: content }} />
            {link && linkType === 'button' && (
              <div className="pt-2 inline-link">
                <Button color={i > 0 ? 'outline-white' : null}>{link.title}</Button>
              </div>
            )}
            {link && linkType === 'arrow' && (
              <div className="pt-2 inline-link">
                <ArrowLink visualOnly={true} link={link} />
              </div>
            )}
          </MaybeLink>
        );
      })}
    </div>
  );
};

export const shadowOffset = (x, y) => {
  let enabled = x || y;
  if (x) {
    enabled = true;
  }
  if (enabled) {
    return {
      style: {
        margin: `-${y ? y : 0}% -${x ? x : 0}%`,
        width: 'auto',
        height: 'auto',
        maxWidth: 'none',
        maxHeight: '150%',
        display: 'inline-block',
      },
    };
  }
  return null;
};
