import React from "react";
import Image from "~/components/elements/Image";
import { formatArticleDate, getFirstCategoryName } from "../../templates/article";
import { Link } from "gatsby"
import { ArrowLink } from "~/components/elements/Typography";
import { Text } from "~/components/flexible/Text";

export const Related = ({ post }) => {
	const related = post.containerArticle?.related;
	if(!related) return null;
	if(post.nodeType === "Partner"){
		return <RelatedPartners post={related[0]} />
	} else {
		return <RelatedArticles related={related} />
	}
}

export const RelatedPartners = ({ post }) => (
	<div className="section container">
		<Link to={post.link} className="flex flex-col space-y-8 md:space-y-0 md:flex-row items-center">
			<div className="w-full md:flex-1">
				<Image image={post.featuredImage?.node} />
			</div>
			<div className="md:w-[50%] md:ml-28">
				<div className="max-w-[480px]">
					<Text 
						subheading="Next Story"
						heading={post.title}
						content={post.containerArticle.masthead?.summary}
						link={{url:post.link, title: "Learn more"}}
						/>
				</div>
			</div>
		</Link>
	</div>
)

export const RelatedArticles = ({ related }) => (
	<div className="section container">
		<h3 className="t-40 pb-12">Related Articles</h3>
		<div className="grid gap-6 grid-cols-1 md:grid-cols-2">
			{related.map((related, i) => (
			<div key={i}>
				<div className="rounded-medium overflow-hidden">
					<Image image={related.featuredImage?.node} />
				</div>
				<h6 className="pt-8 uppercase font-bold text-[12px]">
					{getFirstCategoryName(related.categories)} 
					<span className="opacity-60">
						<span className="mx-4">|</span> {formatArticleDate(related.date)}
					</span>
				</h6>
				<h4 className="t-40 pt-4 pb-4">{related.title}</h4>
				<ArrowLink link={{url: related.link, title: "Read more"}} />
			</div>
			))}
		</div>
	</div>
);