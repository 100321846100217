import React from 'react';
import { Wrapper } from '~/components/article/ArticleFlexible';
import parse from 'html-react-parser';

export const ArticleContent = ({ post }) => {
	const content = post.content;
	if (!content) return null;

	const nodes = parse(content);
	return (
		<div className="space-y-6 md:space-y-12">
			<Wrapper>
				<div className="prose article">{nodes ? nodes.map((item, i) => <div key={i}>{item}</div>) : null}</div>
			</Wrapper>
		</div>
	);
};
