import React, { useState } from 'react';
import { useField } from 'formik';

export default function TextareaField(props) {
  const { label, disabled, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col justify-center group !border-error`}>
      <div className="relative">
        <label
          className={`uppercase t-14 font-medium pointer-events-none absolute top-4 duration-200 ease-in-out transform transition-transform  group-focus-within:-translate-y-10 group-hover:-translate-y-10
          ${disabled && 'text-opacity-30 group-hover:-translate-y-1/2'}
          ${(meta.value?.length > 0 || isInvalid) && '!-translate-y-10'}`}
        >
          {label}
        </label>
        <textarea
          className={`h-[55px] focus-within:h-[150px] bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-all duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30
          ${ (meta.value?.length > 0 || isInvalid) && '!h-[150px]'}
            ${disabled && 'group-hover:border-opacity-30'}
            ${!isInvalid ? '' : 'border-b-error'}`} {...field} {...other}
        />
        {isInvalid && (
          <ErrorMessage error={meta.error} />
        )}
      </div>
    </div>
  );
}


export const ErrorMessage = ({error = ''}) => {
  return (
    <div className="text-[12px] text-error flex items-center mt-1 text-red absolute top-full ">
      {error}
    </div>
  )
}
