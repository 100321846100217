import React, { useState, useRef, useEffect } from "react";

import ReactPlayer from 'react-player'
import { Player } from "@lottiefiles/react-lottie-player";

const LottieAnimation = ({ className = '', animation, play = true, ...props }) => {
  const [readies, setReadies] = useState(0);
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);
  const lottieRef = useRef(null);

  if (animation === 'crossroads') { }

  useEffect(() => {
    if (readies === 2) {
      setPlaying(true);
      lottieRef.current.play();
    }
  }, [readies]);

  return (
    <div className="relative w-full h-full">
      {animation === 'horizons-masthead' ? (
        <div className={`w-full h-full`}>
          <ReactPlayer
            url={`/animations/${animation}.mp4`}
            playing={true}
            muted={true}
            width={"100%"}
            height={"100%"}
            className="react-player-animation"
            loop
          />
        </div>
      ) : (
        <div className={`w-full h-full`}>
          <ReactPlayer
            ref={videoRef}
            url={`/animations/${animation}.mp4`}
            onReady={() => {
              setReadies(readies + 1);
            }}
            onEnded={() => {
              lottieRef.current.setSeeker(0);
              lottieRef.current.play();
              videoRef.current.seekTo(0);
            }}
            playing={playing}
            muted={true}
            width={"100%"}
            height={"100%"}
            className="react-player-animation"
          />
          <div className="absolute top-0 left-0 w-full h-full">
            <Player
              ref={lottieRef}
              src={`/animations/${animation}.json`}
              onEvent={event => {
                if (event === 'load') {
                  setReadies(readies + 1);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LottieAnimation;
