import React from 'react';
import { Button } from '~/components/elements/Typography';

export const Form = ({ type }) => {
  if (type === 'demo') {
    return <FormDemo formID="cta-demo-form" />;
  } else if (type === 'newsletter') {
    return <FormNewsletter formID="cta-newsletter-form" />;
  }
};

export const FormDemo = ({ formID, buttonLabel }) => (
  <FormGeneric
    formID={formID}
    placeholder="Enter your email address"
    action="/demo-request/"
    buttonLabel={buttonLabel || 'Get a demo'}
  />
);

export const FormNewsletter = ({ formID, buttonLabel }) => (
  <FormGeneric
    formID={formID}
    placeholder="Enter your email address"
    action="/contact/"
    buttonLabel={buttonLabel || 'Subscribe'}
  />
);

export const FormGeneric = ({ placeholder, formID, buttonLabel, action }) => (
  <form
    id={formID}
    action={action}
    method="GET"
    className="max-w-[500px] form-wrapper flex-col md:flex-row pt-2 items-center rounded-small bg-white shadow-medium md:p-[9px] md:pl-[25px] flex relative z-[1]"
  >
    <div className="flex-1 rounded-small p-[12px] md:pl-[25px] w-full md:w-auto md:p-0 md:pl-0">
      <input
        name="email"
        className="w-full text-center md:text-left flex-1 text-[15px] text-navy outline-none"
        placeholder={placeholder}
        type="text"
      />
    </div>
    <div className="w-full p-2 md:p-0 md:w-auto md:ml-auto">
      <Button className="w-full">{buttonLabel}</Button>
    </div>
  </form>
);
