import React, {useEffect} from "react";
import Image from "~/components/elements/Image";
import { ArrowLink } from "~/components/elements/Typography";
import { Link } from "gatsby"
import { Button } from "~/components/elements/Typography";
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const getAnimationVariants = (delay) => {
	return {
		hidden: { opacity: 0, y: 15 },
		visible: {
		  opacity: 1,
		  y: 0,
		  transition: {
		    duration: 0.5,
		    delay: delay,
		  },
		},
	};
}

export const Text = (props) => {
	const { subheading, heading, content, logo, link, style, linkStyle } = props;

	let HeadingType = "h2";
	const headingClasses = [];
	const containerClasses = ["space-y-3"];
	let useMastheadHeading = false;

	if(style === "masthead"){
		HeadingType = "h1";
		headingClasses.push("t-52");
		containerClasses.push("lg:space-y-4 relative z-[1]");
		useMastheadHeading = true;
	} else if(style === "masthead-large"){
		HeadingType = "h1";
		headingClasses.push("t-58");
		containerClasses.push("lg:space-y-4");
		useMastheadHeading = true;
	} else if(style === "small"){
		HeadingType = "h3";
		headingClasses.push("t-28");
	} else {
		headingClasses.push("t-40");
	}


	return (
		<div data-content="Text" className={containerClasses.join(" ")}>
			{logo && <div><div className={`inline-block ${style === "masthead" ? "h-[50px]" : "h-[36px]"} mb-1`}><Image imageClasses="h-full" image={logo} /></div></div>}
			{subheading && <h4 className="t-subheading">{subheading}</h4>}
			{heading && !useMastheadHeading && <HeadingType className={headingClasses.join(" ")}>{heading}</HeadingType>}
			{heading && useMastheadHeading && <MastheadHeading className={headingClasses.join(" ")} text={heading} />}
			{content && <div className="t-18 prose" dangerouslySetInnerHTML={{ __html: content }}/>}
			{link && (
				<>
					{style !== "masthead" ? (
						<>
							{linkStyle === 'button' ? (
								<div className="pt-2"><Link to={link.url}><Button>{link.title}</Button></Link></div>
							) : (
								<div className="pt-2 inline-link"><ArrowLink link={link} /></div>
							)}
						</>
					) : (
						<div className="pt-2"><Link to={link.url}><Button>{link.title}</Button></Link></div>
					)}

				</>
			)}
			
			
		</div>
	)
}

export const MastheadHeading = ({className, text}) => {

	const controls = useAnimation("hidden");
	const { ref, inView } = useInView();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
		if (!inView) {
			controls.start('hidden');
		}
	}, [controls, inView]);

	return <h1 ref={ref} className={className}>{text.split(" ").map((word, i) => (
		<motion.span key={`word-${i}`} className="inline-block"
			initial="hidden" 
			variants={{
	        hidden: { opacity: 0, y: 15 },
	        visible: {
	          opacity: 1,
	          y: 0,
	          transition: {
	            duration: 0.4,
	            delay: 0.2 + (i * 0.2),
	          },
	        },
	      }}
	      animate={controls}>{word}&nbsp;</motion.span> 
	))}</h1>
}