import React, { useState } from 'react';
import Image from '~/components/elements/Image';
import { ArrowLink } from '~/components/elements/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import DemoForm from '~/components/forms/DemoForm';
import { ArrowLeft } from '~/components/elements/Icon';
import { Media } from '~/components/flexible/Media';
import { motion, AnimatePresence } from 'framer-motion';

export const DemoLanding = ({ accordion, backgroundImage }) => {
  const logoQuery = useStaticQuery(graphql`
    query {
      wp {
        header {
          globalHeader {
            header {
              logo {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const logo = logoQuery.wp.header.globalHeader.header.logo;
  const [expanded, setExpanded] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const lineStyles = 'absolute !m-0 border-navy border-opacity-10 pointer-events-none';

  return (
    <div className="relative min-h-screen">
      <header className="container max-w-[1440px] pt-12 md:pt-[70px] pb-[50px] relative z-10">
        <Link to="/" className="flex items-center space-x-5">
          <div className="w-[120px]">
            <Image image={logo} />
          </div>
          <div className="flex items-center text-sky font-bold">
            <Media />
            <span>Go Back</span>
          </div>
        </Link>
      </header>
      <section className="container max-w-[1440px] relative z-10 pb-[113px]">
        <div className="flex flex-col lg:flex-row items-start md:space-x-12 relative">
          <div className="w-full lg:w-3/5 relative z-[1]">
            <DemoForm setIsSubmitted={setIsSubmitted} />
          </div>
          <div className="flex-1 pt-24 relative">
            <div
              className={`${lineStyles} top-16 -bottom-24 left-[calc(-60.5vw-45px)] h-full w-12 border-b-[3px] border-r-[3px] `}
              style={{ borderRadius: '0 0 48px 0' }}
            ></div>
            <div
              className={`${lineStyles} top-9 -bottom-24 left-[-60.5vw] h-7 right-[calc(100%-17px)] border-t-[3px] border-l-[3px] border-r-[3px] `}
              style={{ borderRadius: '48px 48px 0 0' }}
            ></div>
            <div
              className={`${lineStyles} top-16 -bottom-24 left-[14px] right-[-120%] lg:-right-16 border-b-[3px] border-l-[3px] `}
              style={{ borderRadius: '0 0 0 48px' }}
            ></div>

            <div className="space-y-10">
              {accordion.map((accordion, i) => (
                <div
                  className="flex items-start space-x-4 md:space-x-16 cursor-pointer"
                  onClick={() => setExpanded(expanded === i ? null : i)}
                >
                  <div className="w-8 h-8 rounded-[100%] overflow-hidden relative flex items-center justify-center">
                    <div
                      className={`absolute inset-0 bg-sky bg-opacity-10 rounded-[100%] transition-transform duration-200 ease-in-out transform scale-0 ${
                        expanded === i && 'scale-100'
                      }`}
                    ></div>
                    <div
                      className={`w-4 h-4 bg-sky rounded-[100%] overflow-hidden transition-transform duration-200 ease-in-out transform scale-[0.625] ${
                        expanded === i && 'scale-100'
                      }`}
                    ></div>
                  </div>
                  <div className="flex-1 space-y-2.5">
                    <h6
                      className={`t-22 font-medium text-navy text-opacity-50 transition-colors duration-200 ease-in-out hover:text-opacity-100 ${
                        expanded === i && 'text-opacity-100'
                      } `}
                    >
                      {accordion.heading}
                    </h6>
                    {accordion.desription && (
                      <AnimatePresence>
                        {expanded === i && (
                          <motion.div
                            className="overflow-hidden"
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                              open: { opacity: 1, height: 'auto' },
                              collapsed: { opacity: 0, height: 0 },
                            }}
                            transition={{ duration: 0.3 }}
                          >
                            <p>{accordion.description}</p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Media animation="404" mediaType="animation" positioningRules="404" />
      {/* <Image image={backgroundImage} className="mt-[-35vw] lg:mt-0 lg:!absolute left-0 bottom-0 right-0" /> */}
    </div>
  );
};
