import { graphql, useStaticQuery } from 'gatsby';

export const useBlogPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          title
          link
          featuredImage {
            node {
              ...Image
            }
          }
          date
          categories {
            nodes {
              name
              link
              slug
              taxonomyCategory {
                icon {
                  ... Image
                }
              }
            }
          }
          containerArticle {
            masthead {
              logo {
                ...Image
              }
              summary
            }
          }
        }
      }
    }
  `);

  return data;
};

