import React from 'react';
import { useField } from 'formik';

export default function CheckboxField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);

  const name = props.name

  return (
    <div
      className={`flex items-start relative group cursor-pointer`}
    >
      <input
        name={name}
        id={name}
        type="checkbox"
        style={{flex: '0 0 18px'}}
        className={`cursor-pointer w-[18px] h-[18px] border border-navy focus-within:border-navy bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out `}
        {...field}
        {...other}
      />
      <label
        htmlFor={name}
        className={`t-14 text-left cursor-pointer pl-2.5`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </div>
  );
}
