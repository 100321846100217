import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputField from '~/components/forms/fields/InputField';
import TextareaField from '~/components/forms/fields/TextareaField';
import CheckboxField from '~/components/forms/fields/CheckboxField';
import { Button } from '~/components/elements/Typography';
import { Link } from 'gatsby';

const validationSchema = Yup.object({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string().required('Required'),
  job: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  message: Yup.string(),
  legal: Yup.boolean().oneOf([true], 'This field must be checked'),
});

export default function ContactForm() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const ref = useRef();

  useEffect(() => {
    if (typeof location === undefined) return null;
    const params = new URLSearchParams(location.search);
    setEmail(params.get('email'));
  }, []);

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        email: email || '',
        phone: '',
        job: '',
        company: '',
        message: '',
        legal: false,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (setIsSubmitted) {
          setIsSubmitted(true);
        }
      }}
    >
      {(props) => (
        <Form className="" id="contact-form" ref={ref}>
          <div className="max-w-[730px] mx-auto">
            <div className="space-y-7 md:space-y-12 bg-white rounded-[7px] px-10 py-16 md:px-[70px] md:py-[60px]">
              {isSubmitted ? (
                <div className="flex flex-col items-center justify-center w-full py-12 my-auto">
                  <h2 className="t-32">Request received</h2>
                  <div className="mt-8">You will receive an email confirmation shortly </div>
                  <Link to="/">
                    <Button className="!px-[44px] !py-4 mt-8">Return home</Button>
                  </Link>
                </div>
              ) : (
                <>
                  <div className="grid md:grid-cols-2 gap-7 gap-y-12">
                    <InputField name="firstname" label="First Name" />
                    <InputField name="lastname" label="Last Name" />
                    <InputField name="email" label="Email Address" />
                    <InputField name="phone" label="Contact Number" />
                    <InputField name="job" label="Job Title" />
                    <InputField name="company" label="Company Name" />
                  </div>
                  <TextareaField name="message" label="Your Message" />
                  <CheckboxField
                    name="legal"
                    label={`I agree to receive occasional communications from Yotta. For more information, please review our <a class="underline" href="/privacy-policy">Privacy Policy</a> `}
                  />
                  <div className="flex justify-center">
                    <Button type="submit" className="!px-[44px] !py-4">
                      Send Enquiry
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          {errorMessage?.length > 0 && <div className="mt-4" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
        </Form>
      )}
    </Formik>
  );
}
