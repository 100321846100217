import React from "react";
import Image from "~/components/elements/Image";
import { LinkedInAlt } from "~/components/elements/Icon";

export const TeamMembers = ({ items }) => (
	<div className="grid grid-cols-1 grid-cols-2 md:grid-cols-3 gap-4 gap-y-6 lg:gap-9 lg:gap-y-12">
		{items.map((item, i) => <TeamMembersItem key={i} {...item} />)}
	</div>
)

export const TeamMembersItem = ({image, name, title, linkedin}) => (
	<div className="space-y-1">
		<Image image={image} />
		<h3 className="pt-2 font-medium">{name}</h3>
		<h4>{title}</h4>
		{linkedin && <div><a href={linkedin}><LinkedInAlt /></a></div>}
	</div>
)