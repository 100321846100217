import React from "react";
import Image from "~/components/elements/Image";
import { MaybeLink, ArrowLink } from "~/components/elements/Typography";
import { formatArticleDate, getFirstCategoryIcon, getFirstCategoryName } from "../../templates/article";
import { useBlogPosts } from "~/hooks/useBlogPosts";

export const ResourcesLanding = () => {
	const { allWpPost } = useBlogPosts();
	const posts = allWpPost.nodes;

	const featured = posts[0];
	const resources = getCategoryPosts(posts, 'newsroom');
	const guides = getCategoryPosts(posts, 'guides');
	const case_studies = getCategoryPosts(posts, 'case-studies');
	const events = getCategoryPosts(posts, 'events');

	const all = posts;

	return (
		<div>
			<div className="mt-32 mb-16 lg:mb-40 lg:mt-56">
				<FeaturedArticle post={featured} />
			</div>
			{resources.length > 0 && (
				<section className="bg-mgrey container-bound section section-top section-bot">
					<div className="container">
						<ArticleGrid posts={resources.slice(0,3)} label={"Resources"} link={{title: "View all Resources", url: "/category/newsroom"}} />
					</div>
				</section>
			)}
			{guides.length > 0 && (
				<section className="section section-top">
					<div className="container">
						<ArticleGrid posts={guides.slice(0,3)} label={"Guides"} link={{title: "View all Guides", url: "/category/guides"}} />
					</div>
				</section>
			)}
			{case_studies.length > 0 && (
				<section className="section section-top">
					<div className="container">
						<ArticleGrid posts={case_studies.slice(0,3)} label={"Case Studies"} link={{title: "View all Case Studies", url: "/category/case-studies"}} />
					</div>
				</section>
			)}
			{events.length > 0 && (
				<section className="section container-bound bg-dark text-white section-top section-bot">
					<div className="container">
						<ArticleGrid style="narrow" posts={events.slice(0,4)} showImage={false} label={"Events"} link={{title: "View all Events", url: "/category/events"}} />
					</div>
				</section>
			)}
			{all.length > 0 && (
				<section className="section section-top">
					<div className="container">
						<ArticleGrid style="narrow" posts={all.slice(0,4)} label={"All content"} />
					</div>
				</section>
			)}
		</div>
	)
}

const getCategoryPosts = (posts, category) => {
	return posts.filter((post) => {
		for(let cat of post.categories.nodes){
			if(cat.slug === category) return true;
		}
		return false;
	})
}

export const FeaturedArticle = ({post}) => (
	<div className="container">
		<div className="relative p-2 pt-36 lg:p-20 lg:pt-80 overflow-hidden rounded-large">
			{post && (
				<>
				{post.featuredImage?.node && <Image className="!absolute top-0 left-0 bottom-0 right-0" objectFit="cover" image={post.featuredImage.node} />}
				<MaybeLink link={{url: post.link}} className="block relative bg-white lg:ml-[50%] p-6 lg:p-12 overflow-hidden rounded-large">
					<ArticleGridInner post={post} />
				</MaybeLink>
				</>
			)}
		</div>
	</div>
)

export const ArticleGrid = ({style, posts, label, link, showImage}) => (
	<div className="container">
		<div className="flex pb-6 md:pb-16 items-center">
			<h2 className="t-40">{label}</h2>
			{link && <div className="ml-auto"><ArrowLink link={link} /></div>}
		</div>
		<div className={`grid grid-cols-1 md:grid-cols-2 ${style === "narrow" ? "lg:grid-cols-4" : "lg:grid-cols-3"} gap-6`}>
			{posts.map((post, i) => (
				<ArticleGridItem key={i} tallImage={style === "narrow"} post={post} size="small" showImage={showImage} />
			))}
		</div>
	</div>
)

export const ArticleGridItem = ({post, tallImage, size, showImage = true}) => (
	<MaybeLink className="block zoom-inner-image" link={{url: post.link}}>
		{showImage ? (
			<div className="image-container relative mb-6 rounded-medium overflow-hidden" style={{aspectRatio: `16 / ${tallImage ? "18" : "12"}`}}>
				{post.featuredImage?.node && <Image className="h-full" objectFit="cover" image={post.featuredImage.node} />}
			</div>
		) : (
			<div className="h-[3px] event-line w-full mb-12 "></div>
		)}

		<ArticleGridInner post={post} size={size} />
	</MaybeLink>
)

export const ArticleGridInner = ({post, size}) => (
	<>
		<h6 className="uppercase font-bold text-[12px] flex items-center">
			<div className="flex space-x-2 items-center">
				<div className="w-4 fw-icon invert-on-dark">
					<Image image={getFirstCategoryIcon(post.categories)} />
				</div>
				<span>
					{getFirstCategoryName(post.categories)} 
				</span>
			</div>
			<span className="opacity-60">
				<span className="mx-4">|</span> {formatArticleDate(post.date)}
			</span>
		</h6>
		<h4 className={`${size === 'small' ? "t-22" : "t-32"} pt-4 pb-4`}>{post.title}</h4>
		<ArrowLink visualOnly={true} link={{url: post.link, title: "Read more"}} />
	</>
);