import React, { useState } from 'react';
import Image from '~/components/elements/Image';
import { Link } from 'gatsby';
import { Button } from '~/components/elements/Typography';
import { ChevronRight } from '~/components/elements/Icon';
import { AnimatePresence, motion } from 'framer-motion';

export default function Header({ data, social }) {
  const [activeMenu, setActiveMenu] = useState(null);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const header = data.globalHeader.header;
  const { logo, menu, buttons } = header;
  header.social = social;

  return (
    <header className="fixed w-full z-10" role="banner">
      <AnimatePresence>{mobileMenuActive && <MobileMenu {...header} />}</AnimatePresence>
      {activeMenu !== null && (
        <div onMouseEnter={() => setActiveMenu(null)} className="absolute top-36 left-0 right-0 h-[500px]"></div>
      )}
      <div className="relative container-bound-margin container-heading-padding bg-white shadow-large rounded-medium relative">
        <div className="flex items-center">
          <div onMouseEnter={() => setActiveMenu(null)} className="xl:ml-3 xl:pr-20">
            <Link to="/">
              <Image image={logo} />
            </Link>
          </div>
          <div className="mx-auto px-4 hidden lg:block">
            <Menu activeMenu={activeMenu} setActiveMenu={setActiveMenu} items={menu} />
          </div>
          <div onMouseEnter={() => setActiveMenu(null)} className="space-x-2 hidden lg:block">
            {buttons.map((button, i) => (
              <Link key={i} to={button.link.url}>
                <Button color={i === 0 ? 'grey' : null}>{button.link.title}</Button>
              </Link>
            ))}
          </div>
          {mobileMenuActive ? (
            <div onClick={() => setMobileMenuActive(false)} className="ml-auto lg:hidden">
              <Button color="black">Close</Button>
            </div>
          ) : (
            <div onClick={() => setMobileMenuActive(true)} className="ml-auto lg:hidden">
              <Button>Menu</Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export const Menu = ({ items, activeMenu, setActiveMenu }) => {
  return (
    <>
      <nav className="space-x-2">
        {items.map((item, i) => {
          const itemClasses = [
            'inline-block px-[16px] py-[12px] text-[14px] rounded-small font-medium transition-color duration-500',
          ];
          if (i === activeMenu) {
            itemClasses.push('bg-lgrey');
          }
          return (
            <Link onMouseEnter={() => setActiveMenu(i)} key={i} className={itemClasses.join(' ')} to={item.link.url}>
              {item.link.title}
            </Link>
          );
        })}
      </nav>
      <AnimatePresence>
        {items.map((item, i) => {
          if (i !== activeMenu) return null;
          if (item.submenu === 'standard') {
            return <StandardSubmenu key={i} items={item.standardSubmenu} />;
          } else if (item.submenu === 'solutions') {
            return <SolutionsSubmenu key={i} {...item.solutionsSubmenu} />;
          } else if (item.submenu === 'industries') {
            return <IndustriesSubmenu key={i} {...item.industriesSubmenu} />;
          }
        })}
      </AnimatePresence>
    </>
  );
};

export const SubmenuWrapper = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 50 }}
    transition={{ duration: 0.4, ease: 'easeInOut' }}
    className="bg-white shadow-large rounded-medium absolute top-[100%] left-0 right-0 z-10 mt-4"
  >
    <div className="container-heading-padding container-submenu py-6 xl:py-10">{children}</div>
  </motion.div>
);

export const IndustriesSubmenu = ({ backgroundImage, heading, mainLinks, secondaryLinks }) => (
  <SubmenuWrapper>
    <div className="flex space-x-12">
      <div className="flex-1">
        <h6 className="t-subheading is-small pb-4">{heading}</h6>
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          {mainLinks.map((item, i) => (
            <StandardSubmenuItem item={item} key={i} />
          ))}
        </div>
      </div>
      <div className="w-[56%]">
        <div className="bg-lgrey py-8 px-12 pr-28 relative">
          <img
            className="absolute top-0 right-0 bottom-0 h-full"
            src={backgroundImage?.localFile?.publicURL}
            alt={backgroundImage?.altText}
          />
          <div className="relative grid grid-cols-2 gap-2">
            {secondaryLinks.map((item, i) => (
              <Link
                className="ml-[-40px] pl-0 hover:pl-[40px] transition-all duration-800 group flex inline-block items-center space-x-3 font-medium hover:text-sky transition-colors duration-200 ease-in-out"
                key={i}
                to={item.link.url}
              >
                <span className="group-hover:opacity-100 opacity-0 transition-opacity duration-800">
                  <HeaderHoverArrow />
                </span>
                <span dangerouslySetInnerHTML={{ __html: item.link.title }} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  </SubmenuWrapper>
);

export const MobileIndustriesSubmenu = ({ backgroundImage, heading, mainLinks, secondaryLinks }) => (
  <div className="bg-mgrey px-6 py-8 rounded-medium">
    <h6 className="t-subheading pb-4">{heading}</h6>
    <div className="space-y-4">
      {mainLinks.map((item, i) => (
        <StandardSubmenuItem item={item} key={i} />
      ))}
    </div>
    <div className="pt-6 space-y-2">
      {secondaryLinks.map((item, i) => (
        <Link className="block font-medium" key={i} to={item.link.url}>
          {item.link.title}
        </Link>
      ))}
    </div>
  </div>
);

export const SolutionsSubmenu = ({ heading, mainLinks, secondaryHeading, secondaryLinks }) => (
  <SubmenuWrapper>
    <div className="flex">
      <div className="flex-1">
        <h6 className="t-subheading is-small pb-4">{heading}</h6>
        <div className="space-y-2">
          {mainLinks.map((item, i) => (
            <Link to={item.link.url} key={i} className="flex w-full items-center space-x-6 xl:space-x-12 group">
              <div className="w-[220px]">
                <Image image={item.image} />
              </div>
              <div className="flex-1">
                <h6 className="flex space-x-4 items-center text-[22px] xl:text-[30px] font-medium group-hover:text-sky transition-colors duration-300 ease-in-out">
                  <span>{item.link.title}</span>
                  <span className="relative top-[-2px] left-0 group-hover:left-2 group-hover:opacity-100 opacity-0 transition-all duration-800">
                    <HeaderHoverArrow />
                  </span>
                </h6>
                <p className="text-[18px]">{item.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="w-[45%] xl:w-[37%] pl-6 ml-6 xl:pl-12 xl:ml-12 border-l border-l-lgrey">
        <h6 className="t-subheading is-small pb-4">{secondaryHeading}</h6>
        <div className="space-y-4">
          {secondaryLinks.map((item, i) => (
            <StandardSubmenuItem item={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  </SubmenuWrapper>
);

export const MobileSolutionsSubmenu = ({ heading, mainLinks, secondaryHeading, secondaryLinks }) => (
  <div className="bg-mgrey px-6 py-8 rounded-medium">
    <h6 className="t-subheading pb-6">{heading}</h6>
    <div className="space-y-4">
      {mainLinks.map((item, i) => (
        <Link to={item.link.url} key={i} className="flex w-full items-center space-x-4">
          <div className="w-[70px]">
            <Image image={item.image} />
          </div>
          <div className="flex-1">
            <h6 className="font-medium">{item.link.title}</h6>
            <p className="text-[12px]">{item.description}</p>
          </div>
        </Link>
      ))}
    </div>
    <h6 className="t-subheading pt-8 pb-4">{secondaryHeading}</h6>
    <div className="space-y-4">
      {secondaryLinks.map((item, i) => (
        <StandardSubmenuItem item={item} key={i} />
      ))}
    </div>
  </div>
);

export const StandardSubmenu = ({ items }) => (
  <SubmenuWrapper>
    <div className={`grid ${items.length > 4 ? 'grid-cols-5' : 'grid-cols-4'} gap-6 xl:gap-8`}>
      {items.map((item, i) => (
        <StandardSubmenuItem item={item} key={i} />
      ))}
    </div>
  </SubmenuWrapper>
);

export const MobileStandardSubmenu = ({ items }) => (
  <div className="bg-mgrey px-6 py-5 space-y-2 rounded-medium">
    {items.map((item, i) => (
      <Link className="font-medium block" to={item.link.url}>
        {item.link.title}
      </Link>
    ))}
  </div>
);

export const StandardSubmenuItem = ({ item }) => (
  <Link to={item.link.url} className="block group">
    <h6 className="flex items-center space-x-1 text-[18px] font-medium mb-[2px] group-hover:text-sky transition-colors duration-200 ease-in-out">
      <span>{item.link.title}</span>
      <span className="relative top-[-2px] left-0 group-hover:left-2 group-hover:opacity-100 opacity-0 transition-all duration-800">
        <HeaderHoverArrow small={true} />
      </span>
    </h6>
    <p className="text-[13px] leading-[21px]">{item.description}</p>
  </Link>
);

export const MobileMenu = ({ logo, menu, buttons, social }) => {
  const [activeMobileMenu, setActiveMobileMenu] = useState(0);
  return (
    <motion.div
      initial={{ opacity: 0, y: -15 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -15 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="bg-white fixed pt-28 pb-6 top-0 left-0 right-0 bottom-0 overflow-auto container-padding"
    >
      <div>
        <nav className="space-y-4">
          {menu.map((item, i) => {
            const indicatorClasses = ['rounded-medium py-[14px] px-[16.5px] ml-auto'];
            const chevronClasses = ['block transition-all'];
            if (activeMobileMenu === i) {
              indicatorClasses.push('bg-mgrey');
              chevronClasses.push('rotate-[-90deg]');
            } else {
              indicatorClasses.push('bg-lgrey');
            }
            return (
              <div key={i}>
                <h6
                  onClick={() => {
                    if (activeMobileMenu === i) {
                      setActiveMobileMenu(null);
                    } else {
                      setActiveMobileMenu(i);
                    }
                  }}
                  className="flex items-center mb-3"
                >
                  <span className="uppercase font-medium">{item.link.title}</span>
                  <span className={indicatorClasses.join(' ')}>
                    <span className={chevronClasses.join(' ')}>
                      <ChevronRight />
                    </span>
                  </span>
                </h6>
                <AnimatePresence>
                  {(() => {
                    if (i !== activeMobileMenu) return null;
                    if (item.submenu === 'standard') {
                      return (
                        <MobileMenuAnimator>
                          <MobileStandardSubmenu key={i} items={item.standardSubmenu} />
                        </MobileMenuAnimator>
                      );
                    } else if (item.submenu === 'solutions') {
                      return (
                        <MobileMenuAnimator>
                          <MobileSolutionsSubmenu key={i} {...item.solutionsSubmenu} />
                        </MobileMenuAnimator>
                      );
                    } else if (item.submenu === 'industries') {
                      return (
                        <MobileMenuAnimator>
                          <MobileIndustriesSubmenu key={i} {...item.industriesSubmenu} />
                        </MobileMenuAnimator>
                      );
                    }
                  })()}
                </AnimatePresence>
              </div>
            );
          })}
        </nav>
      </div>
      <div className="space-y-2 text-center pt-6 pb-8">
        {buttons.map((button, i) => (
          <Link className={`button block${i === 0 ? ' button-grey' : ''}`} key={i} to={button.link.url}>
            {button.link.title}
          </Link>
        ))}
      </div>
      <div className="space-x-6 text-center">
        {social.map((item, i) => (
          <Link className="w-[18px] inline-block" key={i} to={item.link.url}>
            <Image image={item.icon} />
          </Link>
        ))}
      </div>
      <p className="text-[14px] opacity-60 text-center pb-8 pt-4">&copy; 2021 Yotta</p>
    </motion.div>
  );
};

export const MobileMenuAnimator = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: -15 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -15 }}
    transition={{ duration: 0.3, ease: 'easeInOut' }}
  >
    {children}
  </motion.div>
);

export const HeaderHoverArrow = ({ small }) => (
  <svg
    width={small ? '22' : '30'}
    height={small ? '22' : '31'}
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15.9648" r="15" fill="#2A5BC2" />
    <path d="M19.4119 16.0326L14.1857 11.553V15.286H9.70605V16.7792H14.1857V20.5123L19.4119 16.0326Z" fill="white" />
  </svg>
);
