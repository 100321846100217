import React, { useState, useRef, useEffect } from 'react';
import Image from '~/components/elements/Image';
import Animation from '~/components/elements/Animation';

import ReactPlayer from 'react-player';
import { Player } from '@lottiefiles/react-lottie-player';

export const Media = ({ image, animation, positioningRules, spaceTop, spaceBottom, mediaType }) => {
	const wrapperClasses = ['relative media'];
	if (spaceTop) {
		if (spaceTop === 'medium') {
			wrapperClasses.push('lg:mt-24');
		} else if (spaceBottom === 'negative-medium') {
			wrapperClasses.push('lg:mt-[-40px]');
		}
	}
	if (spaceBottom) {
		if (spaceBottom === 'medium') {
			wrapperClasses.push('lg:mb-24');
		} else if (spaceBottom === 'negative-medium') {
			wrapperClasses.push('lg:mb-[-40px]');
		}
	}

	if (positioningRules === 'background-right') {
		return <MediaBackgroundRight image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'background-bottom') {
		return <MediaBackgroundBottom image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'stretch-outside') {
		return <MediaStretchOutside image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'resources-cta') {
		return <MediaResourcesCta image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'horizons') {
		return <MediaHorizonsMasthead image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'support') {
		return (
			<MediaBackgroundBottom
				image={image}
				animation={animation}
				mediaType={mediaType}
				className="media-background-bottom-nobotgrad"
			/>
		);
	} else if (positioningRules === 'alloy') {
		return <MediaAlloyMasthead image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === '404') {
		return <Media404 image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'large-right-header') {
		return <MediaLargeRightHeader image={image} animation={animation} mediaType={mediaType} />;
	} else if (positioningRules === 'service-area') {
		return <MediaServiceArea image={image} animation={animation} mediaType={mediaType} />;
	} else {
		return (
			<div className={wrapperClasses.join(' ')}>
				<MediaInner image={image} animation={animation} mediaType={mediaType} />
			</div>
		);
	}
};

export const MediaBackgroundBottom = ({ image, animation, mediaType, className = '' }) => (
	<div className={`overflow-hidden media-background-bottom absolute left-0 right-0 bottom-0 ${className}`}>
		<MediaInner image={image} animation={animation} mediaType={mediaType} />
	</div>
);

export const MediaBackgroundRight = ({ image, animation, mediaType }) => (
	<div className="overflow-hidden media-background-right -mt-28 -mr-10 ml-[-75%] relative md:mt-0 md:mr-0 md:ml-0 md:absolute top-0 left-0 right-0 bottom-0">
		<MediaInner image={image} animation={animation} mediaType={mediaType} />
	</div>
);

export const MediaStretchOutside = ({ image, animation, mediaType }) => (
	<div className="md:w-[125%] relative left-[-5%] md:left-[-17%] md:mt-[-5%] mb-[-16%] xl:mt-[-7%] xl:mb-[-20%]">
		<Image image={image} />
	</div>
);

export const MediaResourcesCta = ({ image, animation, mediaType }) => (
	<div className="md:w-[122%] relative left-[-5%] md:left-[-15%] lg:left-[-18%] mt-[-5%] mb-[-10%] xl:mt-[-7%] xl:mb-[-20%]">
		<Image image={image} />
	</div>
);

export const MediaServiceArea = ({ image, animation, mediaType }) => (
	<div className="relative top-[-75px] mb-[-75px] md:top-[-100px] md:mb-[-100px] xl:top-[-150px] xl:mb-[-150px]">
		<Image image={image} />
	</div>
);

export const MediaHorizonsMasthead = ({ image, animation, mediaType }) => (
	<div className="pt-[38vw]">
		<div className="media-background-bottom absolute left-0 right-0 bottom-0 h-[64vw]">
			<MediaInner image={image} animation={animation} mediaType={mediaType} />
		</div>
	</div>
);

export const MediaAlloyMasthead = ({ image, animation, mediaType }) => (
	<div className="pt-[40vw]">
		<div className="media-background-bottom absolute left-0 right-0 bottom-0 h-[56vw] z-[-1]">
			<MediaInner image={image} animation={animation} mediaType={mediaType} />
		</div>
	</div>
);

export const Media404 = ({ image, animation, mediaType }) => (
	<div className="pt-[50vw] lg:pt-[10vw]">
		<div className="media-background-bottom absolute left-0 right-0 bottom-0 h-[56vw] z-[-1]">
			<MediaInner animation="alloy-masthead" mediaType="animation" />
		</div>
	</div>
);

export const MediaLargeRightHeader = ({ image, animation, mediaType }) => (
	<div className="md:w-[135%] mt-[-5%] mb-[-14%] md:my-[-14%] left-[-5%] relative">
		<Image image={image} />
	</div>
);

export const MediaInner = ({ mediaType, animation, image }) => {
	return (
		<>
			{mediaType === 'animation' ? (
				<Animation animation={animation} />
			) : (
				<Image className="h-full w-full" objectFit="contain" image={image} />
			)}
		</>
	);
};
