import React from "react";
import { Link } from "gatsby"
import { FadedCorner } from "~/components/elements/Icon";
import { ArrowLink } from "~/components/elements/Typography";

export const PulloutCard = ({ heading, content, link }) => {
	return (
		<div data-content="PulloutCard">
			<div className="p-7 pt-8 pb-4 md:py-10 md:px-12 relative overflow-hidden">
				<div className="faded-decoration absolute top-0 left-0 w-full h-full"><FadedCorner /></div>
				<div className="relative space-y-2 md:space-y-3">
					<h6 className="t-22">{heading}</h6>
					<p className="t-16">{content}</p>
					{link && <div><ArrowLink link={link} /></div>}
				</div>
			</div>
		</div>
	)
}