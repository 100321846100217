import React, {useContext} from "react";
import { Mail, LinkedIn, Twitter } from "~/components/elements/Icon";
import { Link } from "gatsby"
import { PageContext } from "~/templates/Layout";

const socialLinks = [
	{name: "Twitter", icon: <Twitter />, to: (title, url) => {
		return `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
	}},
	{name: "LinkedIn", icon: <LinkedIn />, to: (title, url) => {
		return `http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`;
	}},
	{name: "Mail", icon: <Mail />, to: (title, url) => {
		return `mailto:?subject=${title}&body=Have a look at this article: ${url}`;
	}},
];

export const Share = ({ isSidebar }) => {

	if(typeof window == "undefined") return null;

	const {page} = useContext(PageContext);

	const title = encodeURIComponent(page.title);
	const url = encodeURIComponent(window.location.href);
	
	return (
		<div>
			{isSidebar && <h6 className="text-center uppercase text-[12px] opacity-40 font-bold mb-6">Share</h6>}
			<div className={`${isSidebar ? "space-y-1" : "space-x-3"}`}>
				{socialLinks.map((link, i) => (
					<a
						href={link.to(title, url)}
						style={{border: "1px solid #eee"}} 
						className={`hover:bg-lgrey cursor-pointer px-[18px] py-[18px] inline-block rounded-[100%] ${isSidebar ? "" : " inline-block"}`} 
						key={i}>
						{link.icon}
					</a>
				))}
			</div>
		</div>
	)
}