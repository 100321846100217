import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

// @todo setup these props properly

interface ImageProps {
  image?: any;
  objectFit?: any;
  className?: any;
  loading?: any;
}

function Image({ image, objectFit = 'contain', className = "",  imageClasses = "w-full h-full", loading, ...other }: ImageProps) {
  const innerImageClasses = [imageClasses];
  if(className){
    innerImageClasses.push(className);
  }
  const useChildImage = !image?.localFile?.publicURL.includes(".svg");
  return (
    <>
      {image?.localFile?.childImageSharp && useChildImage ? (
        <GatsbyImage
          image={getImage(image?.localFile)}
          alt={image?.altText}
          imgStyle={{ objectFit: objectFit || "contain" }}
          objectFit={objectFit}
          draggable="false"
          loading={loading}
          className={className}
          {...other}
        />
      ) : (
        <img
          {...other}
          src={image?.localFile?.publicURL}
          alt={image?.altText}
          draggable="false"
          className={innerImageClasses.join(" ")}
        />
      )}
    </>
  );
}

export default Image;
