import React from "react";
import {FadedLeftToRight, FadedRightToLeft, AboutLine, ManagingServicesLine} from "~/components/elements/Icon";

export const Decoration = ({type}) => {

	let inner = null;

	if(type === "line-left-to-right"){
		inner = <FadedLeftToRight/>;
	} else if(type === "line-right-to-left"){
		inner = <FadedRightToLeft/>;
	} else if(type === "about-link"){
		inner = <div className="hidden xl:block relative top-[-170px] left-[-150px] w-[140%] mb-[-10%] about-line-shadow-fix"><AboutLine/></div>;
	} else if(type === "managing-services-line"){
		inner = <div className="hidden xl:inline-block relative top-[-170px] left-[-80px] mb-[-15%] services-line-shadow-fix"><ManagingServicesLine/></div>;
	}

	return (
		<div className="invert-on-dark text-center">
			{inner}
		</div>
	)
}