import React from 'react';
import CookieConsent from 'react-cookie-consent';

const Cookie = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText={`Accept`}
      disableStyles
      disableButtonStyles
      contentClasses={` ml-0 t-16 `}
      containerClasses={
        '!bg-dark p-4 lg:p-12 mx-auto lg:left-[9px] lg:!bottom-[9px] lg:w-[32%] fixed text-white z-[100] '
      }
      buttonClasses={'button group !bg-blue mt-5 t-16'}
      acceptOnScroll={true}
      debug={false}
      cookieName="cookieconsent"
      expires={150}
    >
      <div
        dangerouslySetInnerHTML={{
          __html:
            'We use cookies to give you the best online experience and to keep our site reliable and secure. Read more about them <a class="underline font-medium " href="/privacy-policy"> here </a>',
        }}
      />
    </CookieConsent>
  );
};

export default Cookie;
