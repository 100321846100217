import { Link } from 'gatsby';
import React from 'react';
import Image from '~/components/elements/Image';
import { MaybeLink, ArrowLink } from '~/components/elements/Typography';
import ContactForm from '~/components/forms/ContactForm';
import { SocialLinks } from '~/components/global/Footer';

export const ContactLanding = ({ heading, location }) => (
	<>
		<section className="container section section-top section-header text-center">
			<h1 className="t-58 max-w-[996px] mx-auto mt-8">{heading}</h1>
		</section>
		<section className="container text-center">
			<ContactForm />
		</section>
		<section className="container section section-bot section-top section-large xl:!pt-[133px] xl:!pb-[133px]">
			<div className="bg-white rounded-[9px] overflow-hidden flex flex-wrap lg:flex-nowrap max-w-[1185px] mx-auto px-0">
				<div className="w-full lg:w-3/5">
					<Image image={location.image} className="h-full" objectFit="cover" />
				</div>
				<div className="w-full lg:w-2/5 px-6 py-8 lg:px-[86px] lg:pt-12 xl:pt-[76px] pb-[44px] flex flex-col space-y-5">
					<h3 className="t-32 text-navy">{location.heading}</h3>
					<div dangerouslySetInnerHTML={{ __html: location.address }} className="prose whitespace-pre t-18" />
					<div className="">
						{location.links.map((link, i) => (
							<MaybeLink
								key={i}
								link={link.link}
								className="inline-block text-sky t-18 hover:text-navy transition-colors duration-300 ease-in-out"
							>
								{link.link.title}
							</MaybeLink>
						))}
						<br />
					</div>
					<div className="flex items-center justify-between lg:!mt-auto">
						<SocialLinks social={location.social} />
						<div className="text-sky">
							<ArrowLink link={location.ctaLink} />
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
);
