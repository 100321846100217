import React from "react";
import Image from "~/components/elements/Image";
import { Link } from "gatsby"
import { ArrowLink } from "~/components/elements/Typography";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

export const CaseStudySlider = ({ featuredCaseStudies }) => {
	return (
		<div data-content="CaseStudySlider">
		    <Swiper
		      spaceBetween={25}
		      slidesPerView={1.2}
		    >
				{featuredCaseStudies?.map((post, i) => (
					<SwiperSlide>
						<CaseStudySliderItem post={post} key={i} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

export const CaseStudySliderItem = ({ post }) => {
	const { masthead } = post.containerArticle;
	return (
		<Link to={post.link} className="block bg-white h-full md:flex md:flex-row-reverse rounded-medium overflow-hidden">
			<div className="relative md:w-[50%] xl:w-[48%]">
				<Image className="h-full" objectFit="cover" image={post.featuredImage?.node} />
			</div>
			<div className="flex-1 p-8 lg:p-16 xl:p-20 space-y-4">
				{masthead.logo && <div className="pb-4"><Image className="max-h-[38px]" imageClasses="" image={masthead.logo} /></div>}
				<h3 className="t-22">{masthead.title}</h3>
				{masthead.summary && <p>{masthead.summary}</p>}
				<div className="pt-2 inline-link"><ArrowLink visualOnly={true} link={{url: post.link, title: "Read case study"}} /></div>
			</div>
		</Link>
	);
}